export enum Roles {
    EDITOR = 'editor',
    ADMIN = 'admin'
}

export type JwtToken = {
    role: Roles,
    _id: string,
    exp: number,
    iat: number,
    email: string,
    firstName: string,
    lastName: string,
}

export type UserData = {
    id: string,
    email: string,
    firstName: string,
    lastName: string,
    hash: string,
    salt: string,
    role: Roles,
    activeTokens: string[],
}
