import StarterKit from '@tiptap/starter-kit';

// this should actually be called keyboard shortcuts??
export const CustomShortcuts = StarterKit.extend({
    addKeyboardShortcuts() {
        return {
            'Mod-Shift-x': () => this.editor.commands.toggleStrike(), // CTRL+SHIFT+X
            Tab: () => this.editor.commands.insertContent("\t"),
        }
    },
})
