
import { Editor, useCurrentEditor } from "@tiptap/react";
import { useOutsideClick } from "../../hooks/useOutsideClick";
import TooltipWrapper from "../to-organise/TooltipWrapper";
import * as Icons from 'react-bootstrap-icons';
import '../../assets/scss/list-style-picker.scss';

const listEntries = ['Primul punct', 'Al doilea punct'];

const options = [{
    label: 'Disc',
    value: 'bulletList',
    icon: <Icons.ListUl />
},
{
    label: 'Patrat',
    value: 'squareBulletList',
    icon: <Icons.ListTask />
},
// {
//     label: 'Circles',
//     value: 'emptyCircleBulletList',
//     icon: <Icons.ListUl/>
// },
{
    label: 'Bifa',
    value: 'tickBulletList',
    icon: <Icons.ListCheck />
},
]

const ListStylePicker = ({ editor, onClose }: { editor: Editor, onClose: () => void }) => {

    const wrapperRef = useOutsideClick(onClose);

    const handleChoice = (choice: { label: string, value: string }) => {
        switch (choice.value) {
            case 'bulletList':
                editor.chain().focus().toggleBulletList().run();
                break;
            case 'squareBulletList':
                editor.chain().focus().toggleSquareBulletList().run();
                break;
            case 'emptyCircleBulletList':
                editor.chain().focus().toggleEmptyCircleBulletList().run();
                break;
            case 'tickBulletList':
                editor.chain().focus().toggleTickBulletList().run();
                break;
            default:
                break;
        }
        onClose();
    }

    return (
        <div className='list-style-picker' ref={wrapperRef}>
            {
                options.map(o =>
                    <TooltipWrapper
                        key={o.value}
                        desc={<div style={{textAlign: 'left'}}>{o.label}<ul data-type={o.value}>{listEntries.map(e => <li>{e}</li>)}</ul></div>}
                        placement='bottom'
                    >
                        <div className='list-style-picker-option' onClick={() => handleChoice(o)}>
                            {o.icon}
                        </div >
                    </TooltipWrapper>
                )
            }
        </div >
    )
}

export default ListStylePicker;
