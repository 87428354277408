import { StaticData } from ".";
import { JwtToken } from "./Auth";
import { FrontendBackendMeta, FrontendBackendMetaDraft, FrontendBackendPayload, SearchRequestParams, SearchResponsePayload, RelResponse, RevisionData } from "./DB";
import { FileInfo } from "./Document";

export enum Themes {
    LIGHT = 'light',
    DARK = 'dark'
}

// Import wizard

export enum ImportState {
    UPLOAD = 'upload',
    SPLIT_DOCS = 'split-docs',
    SAVE_DOCS = 'save-docs'
}

export type WizardBaseState = {
    mode: ImportState,
    data?: any,
}

export type UploadWizardState = WizardBaseState & {
    mode: ImportState.UPLOAD,
}

export type SplitDocsWizardState = WizardBaseState & {
    mode: ImportState.SPLIT_DOCS,
    data: any[], // TODO
    meta: FrontendBackendMetaDraft,
}

export type SaveSplitDocsWizardState = WizardBaseState & {
    mode: ImportState.SAVE_DOCS,
    data: {
        doc: Record<string, any>,
        meta: FrontendBackendMetaDraft,
    }[],
}

export type WizardState = UploadWizardState | SplitDocsWizardState | SaveSplitDocsWizardState;

// Banner

export type BannerMessage = {
    type: string,
    text: any,
    errorMessage?: string,
    cannotClear?: boolean,
    autoDismiss?: boolean,
}

export type UserData = {
    firstName: string,
    lastName: string,
    email: string,
    token: string,
}

// User context

export type UserContextType = {
    decodedToken?: JwtToken,
    login: (username: string, password: string) => Promise<boolean>,
    logout: () => void,
}

// Loading and errors context

export type LoadingAndErrorsContextType = {
    errors: BannerMessage[],
    addMessage: (m: BannerMessage) => void,
    removeMessage: (m: BannerMessage) => void,
    updateLoadingState: (key: string, value: boolean) => void,
}

export enum FileTypes {
    IMAGE = 'image',
    FILE = 'file',
}

// Base Context

export type BaseContextType = {
    staticData: StaticData,
    emptyMeta: FrontendBackendMeta,
    draftEmptyMeta: FrontendBackendMetaDraft,
    metaTemplate: FormFieldTemplate[],
    copiedImage?: FileInfo,
    parseDocument: (f: File, showLoading?: boolean) => Promise<Record<string, any> | undefined>,
    fetchDocument: (id: string, type: 'draft' | 'live', showLoading?: boolean) => Promise<FrontendBackendPayload | undefined>,
    saveDocument: (data: Record<string, any>, meta: FrontendBackendMetaDraft, rels: RelResponse[], id: string, showLoading?: boolean) => Promise<string | undefined>,
    addDocument: (data: Record<string, any>, meta: FrontendBackendMetaDraft, rels: RelResponse[], revisions: RevisionData[], liveDocId?: string, showLoading?: boolean) => Promise<string | undefined>,
    publishDocument: (id: string, data: Record<string, any>, meta: FrontendBackendMeta, rels: RelResponse[], newRev: boolean, liveDocId?: string, showLoading?: boolean) => Promise<void>,
    searchDocuments: (params: SearchRequestParams, size: number, showLoading?: boolean) => Promise<SearchResponsePayload | undefined>
    deleteDocument: (id: string, showLoading?: boolean) => Promise<void>,
    updateCopiedImage: (image?: FileInfo) => void,
    getFileUuids: (type: FileTypes) => Promise<string[] | undefined>,
    uploadFile: (file: File, type: FileTypes) => Promise<void>,
    deleteFile: (uuid: string, type: FileTypes) => Promise<void>,
    generateImageURL: (uuid: string) => string,
    getActiveDrafts: () => Promise<string[] | undefined>,
}

// modals

export enum ModalTypes {
    INFO = 'info',
    SUCCESS = 'success',
    WARNING = 'warning',
    DANGER = 'danger',
}

export enum ModalButtonTypes {
    OK = 'success',
    CANCEL = 'gray',
    SAVE = 'success',
    DONT_SAVE = 'danger',
}

export enum Sizes {
    EXTRA_SMALL = 'xs',
    SMALL = 'sm',
    MEDIUM = 'md',
    LARGE = 'lg',
    EXTRA_LARGE = 'xl',
    EXTRA2_LARGE = 'xxl',
}

export enum Variants {
    LIGHT = 'light',
    DARK = 'dark',
}

export enum FormFieldTypes {
    STRING = 'string',
    NUMBER = 'number',
    DATE = 'date',
    BOOLEAN = 'boolean',
    SELECT = 'select',
    MULTI_SELECT = 'multi-select',
    LIST = 'list',
}

export type FormFieldTemplate = {
    type: FormFieldTypes,
    name: string,
    label: string,
    required?: boolean,
    readOnly?: boolean,
    desc?: string,
    optionsKey?: string,
    placeholder?: string,
    additionalProps?: { [key: string]: any },
    error?: string,
    separatorBelow?: boolean,
}

export type StringField = FormFieldTemplate & {
    type: FormFieldTypes.STRING | FormFieldTypes.DATE,
    value: string,
    onChange: (value: string) => void,
    validate?: (value: any) => string | undefined,
};

// export type DateRangeField = FormFieldTemplate & {
//     type: FormFieldTypes.DATERANGE,
//     value: { start: string, end: string },
// };

export type ListField = FormFieldTemplate & {
    type: FormFieldTypes.LIST,
    value: string[],
    options?: string[],
    onChange: (value: string[]) => void,
    validate?: (value: any) => string | undefined,
}

export type SelectField = FormFieldTemplate & {
    type: FormFieldTypes.SELECT,
    value: string,
    options?: string[],
    onChange: (value: string) => void,
    validate?: (value: any) => string | undefined,
}

export type MultiSelectField = FormFieldTemplate & {
    type: FormFieldTypes.MULTI_SELECT,
    value: string[],
    options?: string[],
    onChange: (value: string[]) => void,
    validate?: (value: any) => string | undefined,
}

export type NumberField = FormFieldTemplate & {
    type: FormFieldTypes.NUMBER,
    value: number,
    onChange: (value: number) => void,
    validate?: (value: any) => string | undefined,
};

export type BooleanField = FormFieldTemplate & {
    type: FormFieldTypes.BOOLEAN,
    value: boolean,
    onChange: (value: boolean) => void,
    validate?: (value: any) => string | undefined,
};

export type DateField = FormFieldTemplate & {
    type: FormFieldTypes.DATE,
    value: string,
    onChange: (value: string) => void,
    validate?: (value: any) => string | undefined,
};

export type FormField = StringField | NumberField | BooleanField | ListField | SelectField | MultiSelectField | DateField;
