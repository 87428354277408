import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import _ from "lodash";

import { EditorOptions, generateHTML, EditorProvider, JSONContent } from '@tiptap/react'
import { DB } from '@lex/lex-types';
import FallbackPage from "./FallbackPage";
import { BaseContext } from "../context/BaseContext";
import { UserContext } from "../context/UserContext";
import { LoadingAndErrorsContext } from "../context/LoadingAndErrorsContext";
import { editorExtensions } from "../components/editor-overrides/Extensions";
import DocumentHeader, { GeneratedTextHeader } from "../components/editor/DocumentHeader";
import Controls from '../components/editor/EditorControls';
import { linkBookmarks, BookmarkItem } from "../components/dom-manipulation/link-bookmarks";
import { BookmarkNode, BookmarkType } from "../components/editor-overrides/Bookmark";
import '../assets/scss/document-editor.scss';
import '../assets/scss/document-editor-inner.scss';
import '../assets/scss/lists.scss';

const EditDocumentWrapper = () => {
    const { id, type } = useParams();
    const { decodedToken } = useContext(UserContext);
    const { addMessage } = useContext(LoadingAndErrorsContext);
    const { fetchDocument, addDocument, searchDocuments } = useContext(BaseContext);
    const [initialDocument, setInitialDocument] = useState<{ doc?: Record<string, any>, meta?: DB.FrontendBackendMeta, rels?: DB.RelResponse[], revisions?: DB.RevisionData[], liveDocId?: string, }>({});
    const [documentState, setDocumentState] = useState<'ready' | 'loading' | 'loaded'>('ready');

    const [reload, setReload] = useState(0);

    const navigate = useNavigate();

    useEffect(() => {
        const cancelBackHandler = (e: Event) => {
            window.history.pushState(null, "", window.location.href);
        }
        if (id) {
            setDocumentState('loading');
            switch (type) {
                case 'live':
                    {
                        const fetchDoc = async () => {
                            // check if other drafts exist for this live doc
                            const otherDrafts = await searchDocuments({ publishType: DB.DocumentSearchTypes.DRAFTS_ONLY, liveDocId: id }, 10);

                            if (otherDrafts && otherDrafts.docs.length) {
                                const firstDraft = otherDrafts.docs[0]
                                addMessage({ type: 'warning', text: `Documentul are deja o versiune draft: ${firstDraft.docid}, deschis de ${firstDraft.owner}` });
                                navigate('/');
                                return;
                            }
                            const rawDoc = await fetchDocument(id, type, true);
                            console.log({ rawDoc, fetchLive: true });
                            if (rawDoc) {
                                const idToFetch = await addDocument(rawDoc.data, rawDoc.meta, rawDoc.rels, rawDoc.revisions || [], id, true)
                                navigate(`/edit-document/draft/${idToFetch}`);
                            }
                        }
                        fetchDoc();
                        break;
                    }
                case 'draft':
                    {
                        const fetchDoc = async () => {
                            const rawDoc = await fetchDocument(id, type, true);
                            console.log({ rawDoc, fetchLive: false });
                            if (rawDoc) {
                                const { data: doc, meta, rels, liveDocId, revisions } = rawDoc;
                                setInitialDocument({ doc, meta, rels, revisions, liveDocId });
                                // editor.children = doc;
                                // editor.onChange();
                            }
                            setDocumentState('loaded');
                            window.history.pushState(null, "", window.location.href);

                            window.addEventListener('popstate', cancelBackHandler, { capture: true });
                        }
                        fetchDoc();
                        break;
                    }
            }
        }
        return () => window.removeEventListener('popstate', cancelBackHandler);
    }, [addDocument, addMessage, fetchDocument, id, navigate, searchDocuments, type, reload])

    // if no id, no type or incorrect type, show Fallback
    if (id === undefined || type === undefined || !['draft', 'live'].includes(type)) return <FallbackPage />;

    // if not owner, show Fallback
    if (initialDocument.meta && initialDocument.meta.owner !== undefined && decodedToken && type === 'draft' && initialDocument.meta.owner !== decodedToken.email) {
        return (
            <FallbackPage message={<>Nu ai acces la acest document. Acest document este asignat lui {initialDocument.meta.owner}</>} />
        )
    }

    // if fetch failed, show Fallback (//TODO - proper statuses)
    if (documentState === 'loaded' && !initialDocument.doc) return <FallbackPage showHomeButton onHomeClick={() => navigate('/')} message={<>Nu s-a putut incarca documentul {type} cu id {id}</>} />

    return (
        <>
            {
                initialDocument.doc && initialDocument.meta
                    ? <EditDocument
                        id={id}
                        doc={initialDocument.doc}
                        meta={initialDocument.meta}
                        rels={initialDocument.rels || []}
                        revisions={initialDocument.revisions || []}
                        liveDocId={initialDocument.liveDocId}
                        onSave={() => setReload(prev => prev + 1)}
                    />
                    : null
            }
        </>
    )
}

const EditDocument = ({
    id,
    doc,
    meta,
    rels,
    revisions,
    liveDocId,
    onSave,
}: {
    id: string,
    doc: Record<string, any>,
    meta: DB.FrontendBackendMeta,
    rels: DB.RelResponse[],
    revisions: DB.RevisionData[],
    liveDocId?: string,
    onSave: () => void,
}) => {
    const [hasChanges, setHasChanges] = useState({ meta: false, doc: false });

    const [bookmarks, setBookmarks] = useState<BookmarkItem[]>([]);

    const updateBookmarks = useCallback((data: JSONContent[], visualDelayMs?: number) => {
        const allBookmarks = (data.filter((entry: any) => entry.type === 'lex-bookmark') || []) as BookmarkNode[];

        const startBookmarks = allBookmarks.filter(e => e.attrs.type === BookmarkType.START);
        if (startBookmarks.length === 0) {
            return;
        }
        const bookmarkItems: BookmarkItem[] = [];
        const endBookmarks = allBookmarks.filter(e => e.attrs.type === BookmarkType.END);
        for (const startBook of startBookmarks) {
            const name = startBook.content?.find(e => !e.marks?.includes('bookmark-line'))?.text;
            const pair = endBookmarks.find(e => e.attrs.uid === startBook.attrs.uid);
            if (name) {
                bookmarkItems.push({
                    name,
                    uid: startBook.attrs.uid,
                    hasPair: !!pair,
                })
            } else {
                console.error('Error getting bookmark name', JSON.stringify({ startBook, pair }));
            }
        }
        setBookmarks(bookmarkItems);

        setTimeout(() => linkBookmarks(bookmarkItems, 2), visualDelayMs || 100);
    }, [])

    const editorProps: Partial<EditorOptions> = useMemo(() => ({
        editorProps: {
            attributes: {
                class: 'lex-editor-window'
            },
        },
        extensions: editorExtensions,
        // content: generateHTML(docMock, editorExtensions),
        content: generateHTML(doc, editorExtensions),
        // content: '<p><image-wrapper class="my-image-wrapper">test</image-wrapper></p>',
        onUpdate(props: any) {
            const editorJson = props.editor.getJSON()
            setHasChanges(prev => ({ ...prev, doc: !_.isEqual(doc, editorJson) }));
            updateBookmarks(editorJson.content);
            // The content has changed.
            // console.log(props.editor.getJSON());
        },
        onCreate(props: any) {
            const editorJson = props.editor.getJSON()

            // TODO - find a better way - it doesn't find dom elements straight away
            updateBookmarks(editorJson.content, 500);
            // The editor is ready.
            // console.log(props.editor.getJSON());
        },
        onTransaction(props: any) {

            // The editor state has changed.
            // console.log(props.editor.getJSON());
        },
        enablePasteRules: true,  // can also take an array of rules, i.e. [Link, 'horizontalRule']
        parseOptions: {
            preserveWhitespace: true
        }
    }), [doc, updateBookmarks]);

    const navigate = useNavigate();

    const closeEditor = useCallback(() => {
        navigate('/');
    }, [navigate])

    useEffect(() => {
        setHasChanges({ meta: false, doc: false });
    }, [meta, doc]);


    return (
        <>
            <div className='app-content-wrapper document-editor'>
                <EditorProvider
                    slotBefore={<>
                        <DocumentHeader
                            id={id}
                            meta={meta}
                            rels={rels || []}
                            revisions={revisions || []}
                            notSaved={hasChanges.meta || hasChanges.doc}
                            liveDocId={liveDocId}
                            onPublish={closeEditor}
                            onClose={closeEditor}
                            onChange={(newMeta, newRels) => {
                                setHasChanges(prev => ({ ...prev, meta: !_.isEqual(meta, newMeta) || !_.isEqual(rels, newRels) }))
                            }}
                            onSave={onSave}
                        />
                        <Controls bookmarks={bookmarks} />
                        <GeneratedTextHeader {...meta} />
                    </>}
                    {...editorProps}
                >
                </EditorProvider>
            </div>
        </>
    )
}

export default EditDocumentWrapper;
