import { createRef, useCallback, useEffect, useState } from "react";
import { useOutsideClick } from "../../hooks/useOutsideClick";
import { Form } from "react-bootstrap";

import '../../assets/scss/add-bookmark.scss';

export const AddStartBookmark = ({
    onSubmit,
    onClose,
    position = 'right',
}: {
    onSubmit: (name: string) => void,
    onClose: () => void,
    position?: 'left' | 'right',
}) => {

    const wrapperRef = useOutsideClick(() => onClose());
    const ref = createRef<HTMLInputElement>();

    const [name, setName] = useState('');

    const handleAdd = useCallback(() => {
        if (name) {
            onSubmit(name);
        }
    }, [name, onSubmit])

    useEffect(() => {
        if (ref.current) {
            ref.current.focus();
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <div className={`lex-add-bookmark-inner ${position}`} ref={wrapperRef}>
            <div className='lex-add-bookmark-inner-text'>
                Adauga bookmark
            </div>
            <Form.Control
                ref={ref}
                type='url'
                placeholder='Bookmark name ...'
                value={name}
                onChange={e => setName(e.target.value)}
                tabIndex={801}
                onKeyDown={e => {
                    if (e.key === 'Enter') handleAdd()
                }}
            />
            <div
                className={`lex-button success ${!name ? 'disabled' : 'pointer'}`}
                tabIndex={802}
                onClick={handleAdd}
                onKeyDown={e => {
                    if (e.key === 'Enter') handleAdd()
                }}
            >
                Adauga
            </div>
        </div >
    )
}

export const AddEndBookmark = ({
    options,
    onSubmit,
    onClose,
    position = 'right',
}: {
    options: { name: string, uid: string }[],
    onSubmit: (props: { uid: string, name: string }) => void,
    onClose: () => void,
    position?: 'left' | 'right',
}) => {

    const wrapperRef = useOutsideClick(() => onClose());

    const [selectedOption, setSelectedOption] = useState<{ uid: string, name: string }>();

    return (
        <div className={`lex-add-bookmark-inner ${position}`} ref={wrapperRef}>
            <div className='lex-add-bookmark-inner-text'>
                Selecteaza bookmarkul
            </div>
            <div className='lex-add-bookmark-item-list'>
                {
                    options.map(option =>
                        <div
                            className={`lex-add-bookmark-item ${option === selectedOption ? 'active' : ''}`}
                            onClick={() => setSelectedOption(option)}
                        >
                            {option.name}
                        </div>
                    )
                }
            </div>
            <div
                className={`lex-button success ${!selectedOption ? 'disabled' : 'pointer'}`}
                onClick={() => {
                    if (selectedOption) {
                        onSubmit(selectedOption);
                    }
                }}
            >
                Adauga
            </div>
        </div >
    )
}
