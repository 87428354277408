import React, { useCallback, useContext, useEffect, useState } from 'react';
import dayjs from 'dayjs';
import { StaticData, Document, DB, UI } from '@lex/lex-types';
import useApi from '../hooks/useApi';
import { UserContext } from './UserContext';
import { LoadingAndErrorsContext } from './LoadingAndErrorsContext';
import { FileTypes } from '@lex/lex-types/dist/UI';


const emptyStaticData = { issuers: [], domains: [], publishers: [], docTypes: [], docStatuses: {}, typesVariationMap: {}, rels: [] };

const metaTemplate: UI.FormFieldTemplate[] = [
    {
        type: UI.FormFieldTypes.STRING,
        label: 'Titlu',
        name: 'title',
    },
    {
        type: UI.FormFieldTypes.SELECT,
        label: 'Tipul documentului',
        name: 'type_name',
        optionsKey: 'docTypes',
        required: true,
    },
    {
        type: UI.FormFieldTypes.SELECT,
        label: 'Statusul documentului',
        optionsKey: 'docStatuses',
        name: 'status_name',
        additionalProps: {
            optionsAsObject: true,
        }
    },
    {
        type: UI.FormFieldTypes.NUMBER,
        label: 'Numar',
        name: 'issuer_nr',
        required: true,
    },
    {
        type: UI.FormFieldTypes.NUMBER,
        label: 'An',
        name: 'issue_year',
        required: true,
    },
    {
        type: UI.FormFieldTypes.SELECT,
        label: 'Emitent',
        optionsKey: 'issuers',
        name: 'issuer_name',
    },
    {
        type: UI.FormFieldTypes.NUMBER,
        label: 'Numar M.Of.',
        name: 'publish_nr',
        required: true,
    },
    {
        type: UI.FormFieldTypes.NUMBER,
        label: 'An M.Of.',
        name: 'publish_year',
        required: true,
    },
    {
        type: UI.FormFieldTypes.DATE,
        label: 'Data M.Of.',
        name: 'publish_date',
    },
    {
        type: UI.FormFieldTypes.SELECT,
        label: 'Editor',
        name: 'publisher_name',
    },
    {
        type: UI.FormFieldTypes.LIST,
        label: 'Domenii',
        optionsKey: 'domains',
        name: 'domains',
    }
];

const fullEmptyMeta: DB.FrontendBackendMeta = {
    title: 'Document nou',
    type_name: 'LEGE',
    status_name: 'Fara status',
    issuer_nr: 1,
    issue_date: dayjs().format('YYYY-MM-DD'),
    issue_year: +dayjs().format('YYYY'),
    issuer_name: 'Guvern',
    publish_nr: 1,
    publish_year: +dayjs().format('YYYY'),
    publish_date: dayjs().format('YYYY-MM-DD'),
    publisher_name: 'Monitorul Oficial',
    domains: [],
    owner: '',
};

const emptyMeta: DB.FrontendBackendMetaDraft = {
    type_name: 'LEGE',
    issuer_nr: 1,
    issue_date: dayjs().format('YYYY-MM-DD'),
    issue_year: +dayjs().format('YYYY'),
    owner: '',
};

export const BaseContext = React.createContext<UI.BaseContextType>({
    staticData: emptyStaticData,
    emptyMeta: fullEmptyMeta,
    draftEmptyMeta: emptyMeta,
    metaTemplate,
    copiedImage: undefined,
    parseDocument: async (f: File, showLoading?: boolean): Promise<Record<string, any> | undefined> => { return undefined },
    fetchDocument: async (id: string, type: 'draft' | 'live', showLoading?: boolean) => { return undefined },
    saveDocument: async (data: Record<string, any>, meta: DB.FrontendBackendMetaDraft, rels: DB.RelResponse[], id: string, showLoading?: boolean) => { return undefined },
    addDocument: async (data: Record<string, any>, meta: DB.FrontendBackendMetaDraft, rels: DB.RelResponse[], revisions: DB.RevisionData[], liveDocId?: string, showLoading?: boolean) => { return undefined },
    publishDocument: async (id: string, data: Record<string, any>, meta: DB.FrontendBackendMetaDraft, rels: DB.RelResponse[], newRev: boolean, liveDocId?: string, showLoading?: boolean) => { },
    searchDocuments: async (params: DB.SearchRequestParams, size: number, showLoading?: boolean) => { return undefined },
    deleteDocument: async (id: string, showLoading?: boolean) => { },
    updateCopiedImage: (image?: Document.FileInfo) => { },
    getFileUuids: async (type: FileTypes) => { return undefined },
    uploadFile: async (file: File, type: UI.FileTypes) => { },
    deleteFile: async (uuid: string, type: FileTypes) => { },
    generateImageURL: (uuid: string) => { return "" },
    getActiveDrafts: async () => { return undefined },
})

export const BaseContextWrapper = ({ children }: { children: any }) => {
    const { updateLoadingState, addMessage } = useContext(LoadingAndErrorsContext);
    const { decodedToken } = useContext(UserContext);
    const { getStaticData, search, parseDoc, getDoc, saveDraftDoc, deleteDraftDoc, publishNewRevision, publishUpdate, getFileUuids, generateImageURL, uploadFile, deleteFile, getActiveDraftIds } = useApi(addMessage);

    const [staticData, setStaticData] = useState<StaticData>(emptyStaticData);
    const [emptyMeta, setEmptyMeta] = useState(fullEmptyMeta);
    const [draftEmptyMeta, setDraftEmptyMeta] = useState(emptyMeta);
    const [copiedImage, setCopiedImage] = useState<Document.FileInfo | undefined>();

    const searchDocuments = useCallback(async (params: DB.SearchRequestParams, size: number, showLoading?: boolean) => {
        showLoading && updateLoadingState("Searching documents", true);
        const results = await search(params, size);
        showLoading && updateLoadingState("Searching documents", false);
        return results;
    }, [search, updateLoadingState])

    const parseDocument = useCallback(async (f: File, showLoading?: boolean) => {
        showLoading && updateLoadingState("Parsing document", true);
        const parsedDocument = await parseDoc(f);
        showLoading && updateLoadingState("Parsing document", false);
        return parsedDocument;
    }, [parseDoc, updateLoadingState])

    // save a document to the drafts index
    const saveDocument = useCallback(async (data: Record<string, any>, meta: DB.FrontendBackendMetaDraft, rels: DB.RelResponse[], id: string, showLoading?: boolean) => {
        showLoading && updateLoadingState("Saving document", true);
        const docid = await saveDraftDoc({ docid: id, data, meta: { ...meta, owner: decodedToken?.email || '' }, rels, raw_text: 'TODO', });
        showLoading && updateLoadingState("Saving document", false);
        return docid;
    }, [saveDraftDoc, updateLoadingState, decodedToken?.email])

    const fetchDocument = useCallback(async (id: string, type: 'draft' | 'live', showLoading?: boolean) => {
        showLoading && updateLoadingState("Loading data", true);
        const rawDoc = await getDoc(id, type);

        // checkout draft document
        if (rawDoc && type === 'draft' && rawDoc.meta.owner !== decodedToken?.email) {
            const { data, meta, rels } = rawDoc;
            await saveDocument(
                data,
                {
                    ...meta,
                    owner: decodedToken?.email || '',
                },
                rels,
                id
            );
            showLoading && updateLoadingState("Loading data", false);
            return { ...rawDoc, meta: { ...meta, owner: decodedToken?.email || '' } };
        }
        showLoading && updateLoadingState("Loading data", false);
        return rawDoc;
    }, [getDoc, saveDocument, updateLoadingState, decodedToken?.email])

    // add a new document to the drafts index
    const addDocument = useCallback(async (data: Record<string, any>, meta: DB.FrontendBackendMetaDraft, rels: DB.RelResponse[], revisions: DB.RevisionData[], liveDocId?: string, showLoading?: boolean) => {
        showLoading && updateLoadingState("Saving document", true);
        const docid = await saveDraftDoc({ data, liveDocId, meta: { ...meta, owner: decodedToken?.email || '' }, rels, revisions, raw_text: 'TODO' });
        showLoading && updateLoadingState("Saving document", false);
        return docid;
    }, [saveDraftDoc, updateLoadingState, decodedToken?.email]);

    // save a document to the live index
    const publishDocument = useCallback(async (id: string, data: Record<string, any>, meta: DB.FrontendBackendMeta, rels: DB.RelResponse[], newRev: boolean, liveDocId?: string, showLoading?: boolean) => {
        showLoading && updateLoadingState("Publishing document", true);
        if (newRev || !liveDocId) {
            const ok = await publishNewRevision({ data, meta, rels, raw_text: 'TODO' });
            if (ok) {
                await deleteDraftDoc(id);
            }
        } else {
            const ok = await publishUpdate({ data, meta, docid: liveDocId, rels, raw_text: 'TODO' });
            if (ok) {
                await deleteDraftDoc(id);
            }
        }
        showLoading && updateLoadingState("Publishing document", false);
    }, [deleteDraftDoc, publishNewRevision, publishUpdate, updateLoadingState])

    const deleteDocument = useCallback(async (id: string, showLoading = false) => {
        showLoading && updateLoadingState("Deleting document", true);
        await deleteDraftDoc(id);
        showLoading && updateLoadingState("Deleting document", false);
    }, [deleteDraftDoc, updateLoadingState])

    const updateCopiedImage = (image?: Document.FileInfo) => {
        setCopiedImage(image);
    }

    const getActiveDrafts = useCallback(async () => {
        return await getActiveDraftIds();
    }, [getActiveDraftIds])

    useEffect(() => {
        if (decodedToken) {
            setEmptyMeta(prev => ({ ...prev, owner: decodedToken.email }));
            setDraftEmptyMeta(prev => ({ ...prev, owner: decodedToken.email }));
            const fetch = async () => {
                updateLoadingState("Loading", true);
                const result = await getStaticData();
                updateLoadingState("Loading", false);
                if (result) {
                    setStaticData(result);
                }
            }
            fetch();
        }
    }, [decodedToken, getStaticData, updateLoadingState])

    return (
        <BaseContext.Provider value={{
            staticData,
            emptyMeta,
            draftEmptyMeta,
            metaTemplate,
            copiedImage,
            searchDocuments,
            parseDocument,
            fetchDocument,
            saveDocument,
            addDocument,
            publishDocument,
            deleteDocument,
            updateCopiedImage,
            getFileUuids,
            generateImageURL,
            uploadFile,
            deleteFile,
            getActiveDrafts,
        }}>
            {children}
        </BaseContext.Provider>
    )
}
