export type CSSEntry = {
    raw: string[],
    size: number,
    class: string,
}

export type CSS = {
    [name: string]: CSSEntry,
}

// slate based type

export enum BlockTypes {
    P = 'paragraph',
    HEADER_1 = 'header-one',
    HEADER_2 = 'header-two',
    HEADER_3 = 'header-three',
    HEADER_4 = 'header-four',
    HEADER_5 = 'header-five',
    HEADER_6 = 'header-six',
    UL = 'bulleted-list',
    OL = 'numbered-list',
    LI = 'list-item',
    TABLE = 'table',
    THEAD = 'thead',
    TBODY = 'tbody',
    TFOOT = 'tfoot',
    TABLE_ROW = 'table-row',
    TABLE_CELL = 'table-cell',
    TABLE_HEADER_CELL = 'table-header-cell',
    LINK = 'link',
    BLOCKQUOTE = 'blockquote',
    BOOKMARK = 'bookmark',
    IMAGE = 'image',
}


export enum AlignTypes {
    LEFT = 'left',
    RIGHT = 'right',
    CENTER = 'center',
    // Will exclude justify type alignments since they are horrific to look at
}

export enum ScriptType {
    SUBSCRIPT = 'subscript',
    SUPERSCRIPT = 'superscript',
}

export enum LeafTypes {
    TEXT = 'text',
    LINK = 'link',
    IMAGE = 'image',
}

export type SlateCustomText = {
    type: LeafTypes,
    text: string,
    bold?: boolean,
    italic?: boolean,
    underline?: boolean,
    strikethrough?: boolean,
    emphasize?: boolean,
    color?: string, // any string that is accepted by the color css property
    href?: string,
    src?: string,
    alt?: string,
    scriptType?: ScriptType,
    width?: number,
    height?: number,
}

export type ParagraphBlockTypes =
    | BlockTypes.P
    | BlockTypes.HEADER_1
    | BlockTypes.HEADER_2
    | BlockTypes.HEADER_3
    | BlockTypes.HEADER_4
    | BlockTypes.HEADER_5
    | BlockTypes.HEADER_6

export type SlateBlockBase = {
    type: BlockTypes,
    added?: boolean,
    removed?: boolean,
    alignType?: AlignTypes,
}

export type SlateParagraphBlock = SlateBlockBase & {
    type: ParagraphBlockTypes,
    children: SlateCustomText[],
}

export type SlateUnorderedListBlock = SlateBlockBase & {
    type: BlockTypes.UL,
    children: SlateListItemBlock[],
}

export enum ListStyleTypes {
    NONE = 'none',
    DECIMAL = 'decimal',            // 1,2,3, ...
    LOWER_LATIN = 'lower-latin',    // a,b,c, ...
    LOWER_ROMAN = 'lower-roman',    // i, ii, iii ...
    UPPER_LATIN = 'upper-latin',    // A,B,C, ...
    UPPER_ROMAN = 'upper-roman',     // I, II, III ...
}

export type SlateOrderedListBlock = SlateBlockBase & {
    type: BlockTypes.OL,
    listType?: ListStyleTypes, // list-style-type css property
    children: SlateListItemBlock[],
}

export type SlateListItemBlock = SlateBlockBase & {
    type: BlockTypes.LI,
    children: SlateCustomText[]
}

export type SlateTableBodyBlock = SlateBlockBase & {
    type: BlockTypes.TBODY, // table
    children: SlateTableRowBlock[],
}
export type SlateTableHeaderBlock = SlateBlockBase & {
    type: BlockTypes.THEAD, // table
    children: SlateTableRowBlock[],
}
export type SlateTableFooterBlock = SlateBlockBase & {
    type: BlockTypes.TFOOT, // table
    children: SlateTableRowBlock[],
}

export type SlateTableBlock = SlateBlockBase & {
    type: BlockTypes.TABLE, // table
    children: SlateTableRowBlock[] | (SlateTableBodyBlock | SlateTableHeaderBlock | SlateTableFooterBlock)[],
}

export type SlateTableRowBlock = SlateBlockBase & {
    type: BlockTypes.TABLE_ROW, // tr
    children: (SlateTableCellBlock | SlateTableHeaderCellBlock)[],
}

export type SlateTableCellBlock = SlateBlockBase & {
    type: BlockTypes.TABLE_CELL, // td
    colSpan?: number,
    rowSpan?: number,
    children: SlateCustomText[],
}

export type SlateTableHeaderCellBlock = SlateBlockBase & {
    type: BlockTypes.TABLE_HEADER_CELL, // th
    colSpan?: number,
    rowSpan?: number,
    children: SlateCustomText[],
}

export type SlateLinkBlock = SlateBlockBase & {
    type: BlockTypes.LINK, // a
    href: string,
    children: SlateCustomText[],
}

export type SlateBookmarkBlock = SlateBlockBase & {
    type: BlockTypes.BOOKMARK, // bookmark
    name: string,
}

export type SlateImageBlock = SlateBlockBase & {
    type: BlockTypes.IMAGE, // img
    src: string,
    alt?: string,
    width?: number,
    height?: number,
    children: SlateCustomText[],
}

export type SlateBlockQuoteBlock = SlateBlockBase & {
    type: BlockTypes.BLOCKQUOTE,
    children: SlateParagraphBlock[],
}

export type SlateBlock = SlateParagraphBlock | SlateLinkBlock | SlateTableBlock | SlateUnorderedListBlock | SlateOrderedListBlock | SlateBlockQuoteBlock | SlateListItemBlock | SlateImageBlock;

export type SlateDocument = SlateBlock[];

export type SeparatorBlock = {
    type: 'separator'
};

export type FileInfo = {
    name: string,
    ts: number,
    ext: string,
    url: string,
}

export enum ParagraphTypes {
    TITLE = 'Titlu',
    SUBTITLE = 'Subtitlu',
    PARAGRAPH = 'Paragraf',
}
