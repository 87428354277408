import { useState, useContext, useEffect, useCallback, createRef, useMemo } from "react";
import { Form } from "react-bootstrap";
import { useNavigate, useSearchParams } from "react-router-dom";
import { UserContext } from "../context/UserContext";
import '../assets/scss/login.scss';
import Logo from "../components/to-organise/Logo";
import { Spinner } from "../components/to-organise/Loader";
import { UI } from "@lex/lex-types";

const Login = () => {
    const { login, decodedToken } = useContext(UserContext);
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(false);

    const ref = createRef<HTMLInputElement>();

    const navigate = useNavigate();
    const [searchParams] = useSearchParams();

    const redirectPath = useMemo(() => searchParams.get('r') || '/'
        , [searchParams]);

    const handleLogin = useCallback(async () => {
        if (!username || !password) return;
        setIsLoading(true);
        setError(false);
        const success = await login(username, password);
        setIsLoading(false);
        if (success) {
            navigate(redirectPath)
        } else {
            setError(true);
        }
    }, [login, navigate, password, redirectPath, username]);

    const handleKeyDown = useCallback((e: React.KeyboardEvent<any>) => {
        if (e.key === 'Enter' && username && password) {
            handleLogin();
        }
    }, [handleLogin, password, username])

    useEffect(() => {
        if (decodedToken) {
            navigate(redirectPath)
        }
    }, [decodedToken, navigate, redirectPath])

    useEffect(() => {
        ref.current?.focus();
        // This needs to trigger only on page load
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <div className='lex-login-page'>
            <div className='lex-login-page-logo-wrapper'>
                <Logo size={UI.Sizes.EXTRA2_LARGE} showName />
            </div>
            <div className='lex-login-page-form'>
                <Form.Control
                    type='string'
                    value={username}
                    placeholder='Username'
                    onChange={e => setUsername(e.target.value)}
                    onKeyDown={handleKeyDown}
                    tabIndex={1}
                    ref={ref}
                />
                <Form.Control
                    type='password'
                    value={password}
                    placeholder='Password'
                    onChange={e => setPassword(e.target.value)}
                    onKeyDown={handleKeyDown}
                    tabIndex={2}
                />
                <div
                    className={`lex-button neutral ${!username || !password ? 'disabled' : ''}`}
                    onClick={handleLogin}
                    onKeyDown={handleKeyDown}
                    tabIndex={3}
                >
                    Login {isLoading ? <Spinner /> : null}
                </div>
                {
                    error
                        ? <div className='lex-login-page-error'>
                            Credentialele introduse nu corespund niciunui utilizator inregistrat!
                        </div>
                        : null
                }
            </div>
        </div>
    )
}

export default Login;
