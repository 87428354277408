import { useMemo, useState } from 'react';
import { Form } from 'react-bootstrap';
import { useOutsideClick } from '../../hooks/useOutsideClick';

export type MetaDropDownProps = {
    isOpen: boolean,
    isMulti: false,
    label?: string,
    value: string,
    placeholder?: string,
    options: string[],
    wrap?: boolean,
    renderer?: (props: any) => JSX.Element,
    itemRenderer?: (props: any) => JSX.Element,
    onItemClick: (e: React.MouseEvent<HTMLDivElement, MouseEvent>, item: string) => void,
    onClick: () => void,
    onClickOutside: () => void,
} | {
    isOpen: boolean,
    isMulti: true,
    label?: string,
    value: string[],
    placeholder?: string,
    options: string[],
    wrap?: boolean,
    renderer?: (props: any) => JSX.Element,
    itemRenderer?: (props: any) => JSX.Element,
    onItemClick: (e: React.MouseEvent<HTMLDivElement, MouseEvent>, item: string) => void,
    onClick: () => void,
    onClickOutside: () => void,
}

const DiscreteDropdown = (props: MetaDropDownProps) => {
    const { isOpen, isMulti, label, value, placeholder, options, wrap, renderer, itemRenderer, onClick, onItemClick, onClickOutside } = props;
    const [textSearch, setTextSearch] = useState('');

    const wrapperRef = useOutsideClick(() => onClickOutside());

    const displayValue = useMemo(() => {
        const newValue = isMulti
            ? value.length ? value.join(', ') : placeholder
            : value || placeholder;

        return renderer ? renderer(newValue) : newValue;
    }, [isMulti, placeholder, renderer, value])

    return (
        <div className={`document-header-entry document-header-select ${wrap ? 'wrap' : ''}`} ref={wrapperRef}>
            <span className='lex-pointer document-header-select-trigger' onClick={onClick}>
                {label || null}
                {displayValue}
            </span>
            {
                isOpen
                    ? <div className='document-header-select-list'>
                        <Form.Control
                            type='text'
                            placeholder='Search ...'
                            value={textSearch}
                            onChange={(e) => setTextSearch(e.target.value)}
                        />
                        <div className='document-header-select-list-items'>
                            {
                                options
                                    .filter(option => textSearch ? option.toLowerCase().includes(textSearch.toLowerCase()) : true)
                                    .map((option, idx) =>
                                        <div
                                            key={idx}
                                            className={`document-header-select-item ${(isMulti ? value.includes(option) : value === 'option') ? 'active' : ''}`}
                                            onClick={(e) => onItemClick(e, option)}
                                        >
                                            {
                                                itemRenderer
                                                    ? itemRenderer(option)
                                                    : option
                                            }
                                        </div>
                                    )
                            }
                        </div>
                    </div>
                    : null
            }
        </div>
    )
}

export default DiscreteDropdown;
