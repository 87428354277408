import React, { useCallback, useState } from 'react';
import { UI } from '@lex/lex-types';
import Banner from '../components/to-organise/Banner';
import Loader from '../components/to-organise/Loader';
// import '../styles/errors.scss'

export const LoadingAndErrorsContext = React.createContext<UI.LoadingAndErrorsContextType>({
    errors: [] as UI.BannerMessage[],
    addMessage: (m: UI.BannerMessage) => { },
    removeMessage: (m: UI.BannerMessage) => { },
    updateLoadingState: (key: string, value: boolean) => { },
})

export const LoadingAndErrorsContextWrapper = ({ children }: { children: any }) => {
    const [errors, setErrors] = useState<UI.BannerMessage[]>([])
    const [isLoading, setIsLoading] = useState<{ [key: string]: boolean }>({});

    const updateLoadingState = useCallback((key: string, value: boolean) => {
        setIsLoading(prev => ({ ...prev, [key]: value }));
    }, [])

    const addMessage = useCallback((m: UI.BannerMessage) => {
        if (!m.cannotClear) {
            setErrors(prev => ([
                ...prev.filter(e => e.text !== m.text),
                m
            ]))
        }
    }, [])

    const removeMessage = useCallback((m: UI.BannerMessage) => {
        setErrors(prev => prev.filter(e => e.type !== m.type && e.text !== m.text && (m.errorMessage ? e.errorMessage !== m.errorMessage : true)))
    }, [])

    return (
        <LoadingAndErrorsContext.Provider value={{
            errors,
            addMessage,
            removeMessage,
            updateLoadingState,
        }}>
            {
                errors.length
                    ? <div className={`lex-errors`}>
                        {
                            errors.map((e, idx) =>
                                <Banner
                                    key={idx}
                                    type={e.type}
                                    text={e.text}
                                    details={e.errorMessage}
                                    autoDismiss={e.autoDismiss}
                                    onDismiss={() => removeMessage(e)}
                                />)
                        }
                    </div>
                    : null
            }
            {
                Object.keys(isLoading).some(key => isLoading[key])
                    ? <Loader />
                    : null
            }
            {children}
        </LoadingAndErrorsContext.Provider>
    )
}
