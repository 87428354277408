export const ACT_INDEX = 'newact,act';
export const NEW_ACT_INDEX = 'newact';
export const DRAFT_INDEX = 'newactdraft';
export const REVCHAIN_INDEX = 'revchain';
export const REL_INDEX = 'rel';

export type FrontendBackendMeta = {
    title: string,
    type_name: string, // ex: ORDIN ADMINISTRATIE PUBLICA; Valori posibile: /api/static/data {doctypes}
    status_name: string, // ex: Modificat; Valori posibile: /api/static/data {status}
    domains: string[], // /api/static/data {domains}
    publish_nr: number, // numarul monitorului oficial
    publisher_name: string, // ex: Monitorul Oficial; Valori posibile: TBD
    publish_date: string, // cu ce e diferit de modified/created?
    issuer_name: string, // numele emitentului; Valori posibile: /api/static/data {issuers}
    issuer_nr: number, // numarul publicarii
    issue_date: string, // data publicarii primei revizii
    issue_year: number, // anul publicarii primei revizii
    publish_year: number, // anul publicarii
    owner: string,
    mtime?: string,
}

export type FrontendBackendMetaDraft = {
    type_name: string, // ex: ORDIN ADMINISTRATIE PUBLICA; Valori posibile: /api/static/data {doctypes}
    issuer_nr: number, // numarul publicarii
    issue_date: string, // data publicarii primei revizii
    issue_year: number, // anul publicarii primei revizii
    owner: string,
    title?: string,
    status_name?: string, // ex: Modificat; Valori posibile: /api/static/data {status}
    domains?: string[], // /api/static/data {domains}
    publish_nr?: number, // numarul monitorului oficial
    publisher_name?: string, // ex: Monitorul Oficial; Valori posibile: TBD
    publish_date?: string, // cu ce e diferit de modified/created?
    issuer_name?: string, // numele emitentului; Valori posibile: /api/static/data {issuers}
    publish_year?: number, // anul publicarii
    mtime?: string,
}

export enum RelStates {
    DELETED = 'deleted',
    NEW = 'new',
    EXISTING = 'existing',
}

export type FrontendBackendPayload = { // care e folosit la /insert-doc si ca raspuns la /get-doc
    data: Record<string, any>,
    raw_text: string,
    meta: FrontendBackendMeta,
    rels: RelResponse[],
    revisions?: {
        rev_date: string,
        id: string,
        rev_type: string,
    }[],
    liveDocId?: string,
}

export type FrontendBackendPayloadUpdateDoc = FrontendBackendPayload & { // /update-doc
    docid: string,
}

export type RevisionData = {
    rev_date: string,
    id: string,
    rev_type: string,
}

export type FrontendBackendPayloadSaveDraft = { // save draft
    data: Record<string, any>,
    raw_text: string,
    meta: FrontendBackendMetaDraft,
    rels: RelResponse[],
    revisions?: RevisionData[],
    docid?: string,
    liveDocId?: string,
};

export type RelResponse = {
    relatedDocId: string,
    state: RelStates,
    rel_type: string,
    dbId?: string, // primit pe /get-doc; de dat inapoi pe /update-doc
    meta?: FrontendBackendMeta, // primit de pe /get-doc
}

export type Rel = {
    id?: string,
    ctime: string,
    mtime: string,
    src: string,
    dst: string,
    lex_src: string,
    lex_dst: string,
    rel_type: number
}

export type RevchainUpdate = {
    mtime: string,
    revisions: {
        rev_date: string,
        id: string,
        lexid: string,
        rev_type: string,
    }[],
}

export type Revchain = RevchainUpdate & {
    ctime: string,
}

export type SearchDocumentCommonFields = {
    type_name: string, // ex: ORDIN ADMINISTRATIE PUBLICA; TBD: valori posibile?
    issuers: [{ // pot fi mai multi issuers?
        issuer_nr: number, // numarul publicarii
        issuer_code: number, // codul issuer-ului
        issuer_name: string, // numele issuer-ului
    }],
    title: string,
    domains: {
        domain_name: string,
        domain_code: string,
    }[],
    issue_year: number, // anul publicarii primei revizii
    status_name: string, // Modificat/Abrogat/etc
    publish_year: number, // anul publicarii
    publish_nr: number, // numarul monitorului oficial
}

export type Document = SearchDocumentCommonFields & {
    revdata: {
        rev_number: number,
        chain_id: string,
        rev_date: string, // data modificarii curente
        rev_type: string,
        prev_rev_date?: string,
        next_rev_date?: string,
    },
    newtype: string, // TBD: cu ce difera de type_name?
    end_date: string, // anul abrogarii
    publisher_name: string, // ex: Monitorul Oficial; TBD: valori posibile?
    publish_date: string, // cu ce e diferit de modified/created?
    status_code: string, // ex: M (modificat, cred); TBD: valori posibile?\
    mtime: string, // last modified in db time
    modified: string, // anul modificarii in legislatie, cred?
    ctime: string, // creation time in db
    lexid: string, // generat: 69700555.17-20230626; 6970 - issuer_nr, 05 - type code; 55 - issuer_code; .17 - issue_year; -20230626 - data documnentului
    created: string, // ??????
    history: {
        message: string, // arj-ul din care a fost importat
        when: string, // data importului
        who: string, // cine a importat? arj importer/si probabil lex-editor
    }[],
    deleted: boolean, // folosit pentru concatenari
    type_code: number, // codul tipului de act; TBD: valori posibile?
    data: Record<string, any>,
    raw_text: string,
    issue_date: string, // data completa a publicarii primei revizii
    owner?: string,
    isDraft?: boolean,
    html?: string,
}

export enum DocumentSearchTypes {
    DRAFTS_ONLY = 'drafts-only',
    LIVE_ONLY = 'live-only',
    ALL = 'all',
}

export type SearchRequestParams = {
    issuer_nr?: number,
    year?: number,
    nr_mof?: number,
    year_mof?: number,
    issuer?: string,
    domain?: string,
    type?: string,
    size?: number,
    title?: string,
    owner?: string,
    publishType?: DocumentSearchTypes,
    liveDocId?: string,
}

export type SearchFilters = {
    [key: string]: {
        [secondKey: string]: string | number | { [thirdKey: string]: string }
    }
}[]

export type SearchQuery = {
    _source: {
        includes: string[]
    },
    query: {
        bool: {
            must: SearchFilters
        }
    },
    sort?: {
        [key: string]: {
            order: string,
        }
    }[],
    size?: number,
}

export type DatabaseSearchResultDocsPayload = (Document & { docid: string })

export type DatabaseSearchResult = {
    docs: DatabaseSearchResultDocsPayload[],
    took: number,
}

export type DraftSearchResultDocsPayload = (FrontendBackendPayloadSaveDraft & { ctime: string, mtime: string })

export type DraftSearchResult = {
    docs: DraftSearchResultDocsPayload[],
    took: number,
}

export type BookmarkMeta = {
    bookmarkId: string,
    bookmarkName: string
}

export type SearchResponseEntry = {
    docid: string,
    type_name: string,
    issuer: string,
    issue_year: number,
    issue_nr: number,
    domains: string[],
    status_name: string,
    publish_nr: number,
    publish_date: string,
    title: string,
    owner: string,
    isDraft?: boolean,
    mtime: string,
    liveDocId?: string,
    bookmarks: BookmarkMeta[],
}

export type SearchResponsePayload = {
    docs: SearchResponseEntry[],
    took: number,
}

export type DocWithRevChainAndRel = {
    data: Record<string, any>,
    publish_year: number,
    publish_nr: number,
    publisher_name: string,
    type_name: string,
    status_name: string,
    title: string,
    issue_year: number,
    domains: string[],
    issuers: {
        issuer_nr: number,
        issuer_name: string,
    },
    revisions: {
        rev_date: string,
        id: string,
        rev_type: string,
    }[],
    rels: RelResponse[],
    owner: string,
    mtime: string,
}

export type CreateArjPayload = {
    date: string // YYYY-MM-DD
}

export type GetBookmarkPayload = {
    docid: string,
    bookmarkName: string,
}
