import * as DB from './DB';
import * as Document from './Document';
import * as UI from './UI';
import * as Auth from './Auth';
import * as WS from './WS';

type StaticData = {
    issuers: string[],
    domains: string[],
    publishers: string[],
    docTypes: string[],
    typesVariationMap: Record<string, string[]>,
    docStatuses: {
        [status_name: string]: string // value is status_code
    },
    rels: string[],
}

export {
    DB,
    Document,
    UI,
    Auth,
    WS,
    StaticData,
}
