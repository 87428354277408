import { useContext, useEffect, useState } from "react";
import { DB } from "@lex/lex-types";
import { useOutsideClick } from "../../hooks/useOutsideClick";
import { BaseContext } from "../../context/BaseContext";
import SearchBox from "../to-organise/SearchDocumentsBox";
import SearchResult from "../to-organise/SearchResult";

import '../../assets/scss/add-link.scss';

const AddLink = ({
    value,
    onChange,
    onClose,
    onRemove,
}: {
    value?: string,
    onChange: (docId: string) => void,
    onClose: () => void,
    onRemove: () => void,
}) => {
    const { searchDocuments } = useContext(BaseContext);
    const [searchResults, setSearchResults] = useState<DB.SearchResponseEntry[]>([]);
    const wrapperRef = useOutsideClick(() => onClose());

    // TODO = loading state to show it's loading
    useEffect(() => {
        if (value) {
            const fetchResults = async () => {
                const results = await searchDocuments({ publishType: DB.DocumentSearchTypes.LIVE_ONLY, liveDocId: value.replace(/^view\//, '') }, 1);
                if (results) {
                    setSearchResults(results.docs);
                }
            }
            fetchResults();
        }
    }, [searchDocuments, value])

    return (
        <div className='lex-add-link-inner left nopadding' ref={wrapperRef}>
            <SearchBox
                publishType={DB.DocumentSearchTypes.LIVE_ONLY}
                onSearch={(results => setSearchResults(results))}
                startWithAdvancedOpen
            />
            <div className='lex-document-picker-results'>
                {
                    searchResults.map(result =>
                        <SearchResult
                            {...result}
                            canOpen
                            canDelete
                            onDelete={onRemove}
                            noConfirmDelete
                            onClick={() => onChange(result.docid)}
                        />
                    )
                }
            </div>
        </div>
    )
}

export default AddLink;
