import { DB } from '@lex/lex-types';
import { useContext, useMemo, useState } from 'react';
import { Form } from 'react-bootstrap';
import '../../assets/scss/rel-add.scss';
import { BaseContext } from '../../context/BaseContext';
import SearchBox from './SearchDocumentsBox';
import SearchResult from './SearchResult';

type PartialRel = {
    relatedDocId?: string,
    state?: DB.RelStates,
    rel_type?: string,
    dbId?: string,
}

const RelAdd = ({
    onAdd,
    onClose,
}: {
    onAdd: (rel: Omit<DB.RelResponse, 'meta'>) => void,
    onClose: () => void
}) => {
    const { staticData } = useContext(BaseContext);
    const [relData, setRelData] = useState<PartialRel>({ state: DB.RelStates.NEW });
    const [searchResults, setSearchResults] = useState<DB.SearchResponseEntry[] | undefined>();
    const canAdd = useMemo(() => relData.rel_type && relData.relatedDocId, [relData])

    return (
        <div className='lex-rel-add'>
            <div className='lex-rel-add-header'>
                Adauga legatura
            </div>
            <div className='lex-rel-add-body'>
                <Form.Select
                    value={relData.rel_type || undefined}
                    onChange={e => setRelData(prev => ({ ...prev, rel_type: e.target.value }))}
                >
                    {
                        !relData.rel_type
                            ? <option>Selecteaza tipul legaturii</option>
                            : null
                    }
                    {
                        staticData.rels.map(relType =>
                            <option key={relType} value={relType}>{relType}</option>
                        )
                    }
                </Form.Select>
                <div className='lex-rel-add-search'>
                    <SearchBox
                        publishType={DB.DocumentSearchTypes.LIVE_ONLY}
                        onSearch={(results => setSearchResults(results))}
                    />
                    <div className='lex-rel-add-search-results'>
                        {
                            searchResults
                                ? searchResults.length
                                    ? searchResults.map(result =>
                                        <div key={result.docid} className={`lex-rel-add-search-results-entry ${result.docid === relData.relatedDocId ? 'selected' : ''}`}>
                                            <SearchResult
                                                {...result}
                                                canOpen
                                                onClick={() => setRelData(prev => ({ ...prev, relatedDocId: result.docid, meta: result }))}
                                            />
                                        </div>
                                    )
                                    : <>Nu s-au gasit rezultate</>
                                : null
                        }
                    </div>
                </div>
            </div>

            <div className='lex-rel-add-actions'>
                <div className={`lex-button success ${!canAdd ? 'disabled' : ''}`} onClick={() => { if (canAdd) onAdd(relData as DB.RelResponse) }}>
                    Adauga
                </div>
                <div className='lex-button neutral' onClick={onClose}>
                    Inchide
                </div>
            </div>
        </div>
    )
}

export default RelAdd;
