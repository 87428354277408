import { useCallback, useState } from "react";
import * as Icons from 'react-bootstrap-icons';
import { Form } from "react-bootstrap";
import '../../assets/scss/searchbox.scss';

const SearchBoxGeneric = ({
    onSearch,
    placeholder,
}: {
    onSearch: (text: string) => void,
    placeholder?: string,
}) => {
    const [internalText, setInternalText] = useState('');
    const onKeyDown = useCallback((e: React.KeyboardEvent<HTMLElement>) => {
        if (e.key === 'Enter') {
            onSearch(internalText);
        }
    }, [internalText, onSearch])

    return (
        <div className='lex-search'>
            <div className='lex-search-text form-control'>
                <Form.Control
                    type='text'
                    name='textSearch'
                    placeholder={placeholder || 'Cauta...'}
                    value={internalText}
                    onKeyDown={(e) => onKeyDown(e)}
                    onChange={(e) => setInternalText(e.target.value)}
                    autoComplete='off'
                />
                <Icons.Search className='lex-search-icon' />
            </div>
        </div>
    )
}

export default SearchBoxGeneric;
