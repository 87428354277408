import { DB, StaticData, UI } from '@lex/lex-types';
import { create } from 'apisauce'
import { ApiResponse } from 'apisauce';
import { useCallback, useMemo } from 'react';

export const backendUrl = (window as any)._env_.REACT_APP_BACKEND_URL

const api = create({
    baseURL: backendUrl,
    // withCredentials: true,
});

const methods: { [key in 'get' | 'post' | 'put' | 'patch' | 'delete']: <T, U = T>(url: string, data?: any, axiosConfig?: any) => Promise<ApiResponse<T, U>> } = {
    get: api.get,
    post: api.post,
    put: api.put,
    patch: api.patch,
    delete: api.delete,
}

export const getCookie = (name: string): string => {
    const cookies = document.cookie.split(';').map((cookie) => cookie.trim());
    const cookie = cookies.find((cookie) => cookie.startsWith(`${name}=`));

    if (cookie) {
        return cookie.split('=')[1];
    }

    return '';
};

const apiWrap = (method: 'get' | 'post' | 'put' | 'patch' | 'delete', addMessage: (m: UI.BannerMessage) => void) => async function wrapper<ResponseType, ParamsType, ConfigType>(name: string, route: string, params?: ParamsType, config?: ConfigType, options: { retryCounter?: number, returnOriginalResponse?: boolean } = {}): Promise<ResponseType | undefined> {
    const configWithToken: any = {
        ...config,
    }
    configWithToken.headers = {
        ...configWithToken.headers,
        token: `Bearer ${getCookie('jwtToken')}`,
    }
    const { retryCounter = 0, returnOriginalResponse = false } = options
    const { ok, data, status, problem, originalError, duration, headers } = await methods[method]<ResponseType>(route, params, configWithToken)
    if (!ok) {
        if (status === 403 && retryCounter <= 1) {
            return await wrapper(name, route, params, configWithToken, { ...options, retryCounter: retryCounter + 1 })
        }
        if (problem === 'NETWORK_ERROR' || status === 502) {
            // TODO: refactor this to stop the response from propagating (with throw & ResponseType | undefined)
            addMessage({ text: `${name} - Network error`, type: 'error' })
        }
        else if (status === 404) {
            addMessage({ text: `${name} - Error 404`, type: 'error', errorMessage: 'The resource does not exist.' })
        }
        else if (status === 500) {
            addMessage({ text: `${name} - Internal server error`, type: 'error', errorMessage: originalError.message })
        }
        else if (status) {
            addMessage({ text: `${name} - Error ${status}`, type: 'error', errorMessage: originalError.message })
        } else {
            addMessage({ text: `${name} - Unknown error`, type: 'error', errorMessage: originalError.message })
        }
        console.error('Error fetching data', problem, originalError)
        if (returnOriginalResponse) return { ok, status, problem, originalError } as any
        const custom_error_to_display = (data as any)?.custom_error_to_display
        if (custom_error_to_display) return data
        return
    }
    if (!data) {
        addMessage({ text: `${name} - No data received`, type: 'error' })
        console.error('Error fetching data', problem, originalError)
        return
    }
    if (returnOriginalResponse) return { ok, data, status, duration, headers } as any
    return data
}

const useApi = (addMessage: (m: UI.BannerMessage) => void) => {

    const apiWrapper = useMemo(() => ({
        get: apiWrap('get', addMessage),
        post: apiWrap('post', addMessage),
        put: apiWrap('put', addMessage),
        patch: apiWrap('patch', addMessage),
        delete: apiWrap('delete', addMessage),
    }), [addMessage]);

    const login = useCallback(async (email: string, password: string): Promise<string | undefined> => {
        const { status, headers } = await api.post('/api/login', { email, password });
        switch (status) {
            case 200:
                if (headers && headers.token) {
                    return (headers.token)
                }
                return undefined;
            default:
                return undefined;
        }
    }, []);

    const refreshToken = useCallback(async (): Promise<string | undefined> => {
        const { status, headers } = await api.post('/api/refresh', null, { headers: { token: `Bearer ${getCookie('jwtToken')}` } });
        switch (status) {
            case 200:
                if (headers && headers.token) {
                    return (headers.token);
                }
                return undefined;
            case 400:
            case 401:
            case 403:
            case 500: // TODO what should I do here ?!?
            default:
            case 404: // redirect to login
                return undefined;
        }
    }, []);

    const logout = useCallback(async () =>
        api.post('/api/logout', null, { headers: { token: `Bearer ${getCookie('jwtToken')}` } })
        , []);

    const uploadFile = useCallback(async (file: File, type: UI.FileTypes): Promise<void> => {
        const formData = new FormData();
        formData.append('file', file);
        return apiWrapper.post('Upload image', `/api/files/${type}/upload`, formData, { headers: { 'Content-Type': file.type } })
    }, [apiWrapper]);

    const deleteFile = useCallback(async (uuid: string, type: UI.FileTypes): Promise<void> => {
        return apiWrapper.post('Download image', `/api/files/${type}/delete/${uuid}`);
    }, [apiWrapper]);

    const getFileUuids = useCallback(async (type: UI.FileTypes): Promise<string[] | undefined> =>
        apiWrapper.get('Get Images', `/api/files/${type}/all`, null)
        , [apiWrapper])

    const generateImageURL = (fPath: string): string => `${backendUrl}/api/files/image/${fPath}`;

    const getStaticData = useCallback(async (): Promise<StaticData | undefined> =>
        apiWrapper.get('Loading static data', '/api/static/data', null)
        , [apiWrapper]);

    const search = useCallback(async (searchParams: any, size: number): Promise<DB.SearchResponsePayload | undefined> =>
        apiWrapper.post('Search documents', `/api/docs/search`, { size, ...searchParams })
        , [apiWrapper]);

    const parseDoc = useCallback(async (file: File): Promise<Record<string, any> | undefined> => {
        const formData = new FormData();
        formData.append('rawhtml', file);
        return apiWrapper.post('Parse document', `/api/parse/html`, formData, { headers: { 'Content-Type': 'text/html' } });
    }, [apiWrapper]);

    const getDoc = useCallback(async (id: string, type: 'draft' | 'live'): Promise<DB.FrontendBackendPayload | undefined> =>
        await apiWrapper.get('Get document', `/api/docs/get-doc/${type}/${id}`, null)
        , [apiWrapper]);

    const getActiveDraftIds = useCallback(async (): Promise<string[] | undefined> =>
        await apiWrapper.get('Get active drafts', '/api/active-drafts', null)
        , [apiWrapper]);

    const saveDraftDoc = useCallback(async (payload: DB.FrontendBackendPayloadSaveDraft): Promise<string | undefined> =>
        await apiWrapper.post('Save document draft', `/api/docs/save-draft`, payload)
        , [apiWrapper]);

    const deleteDraftDoc = useCallback(async (id: string): Promise<string | undefined> =>
        await apiWrapper.delete('Delete document draft', `/api/docs/delete-draft/${id}`, null)
        , [apiWrapper]);

    const publishNewRevision = useCallback(async (payload: DB.FrontendBackendPayload): Promise<boolean> => {
        const res = await apiWrapper.post('Publish new revision', `/api/docs/insert-doc`, payload);
        return !!res;
    }, [apiWrapper]);

    const publishUpdate = useCallback(async (payload: DB.FrontendBackendPayloadUpdateDoc): Promise<boolean> => {
        const res = await apiWrapper.post('Publish update', `/api/docs/update-doc`, payload);
        return !!res;
    }, [apiWrapper]);

    const createArj = useCallback(async (date: string) =>
        apiWrapper.post('Create ARJ', '/api/docs/create-arj', { date })
        , [apiWrapper])

    return { login, logout, refreshToken, getStaticData, search, parseDoc, getDoc, getActiveDraftIds, saveDraftDoc, deleteDraftDoc, publishNewRevision, publishUpdate, uploadFile, deleteFile, getFileUuids, generateImageURL, createArj };

}

export default useApi;
