import { useMemo } from 'react';
import * as Icons from 'react-bootstrap-icons';
import { UI } from '@lex/lex-types';
import '../../assets/scss/modal.scss';

const Modal = ({
    type = UI.ModalTypes.INFO,
    title,
    body,
    buttons,
}: {
    type?: UI.ModalTypes,
    title: string,
    body: JSX.Element,
    buttons: { type: UI.ModalButtonTypes, text: string, onClick: () => void }[],
}) => {

    const icon = useMemo(() => {
        switch (type) {
            case UI.ModalTypes.INFO:
                return <Icons.InfoCircle />;
            case UI.ModalTypes.WARNING:
                return <Icons.ExclamationTriangle />;
            case UI.ModalTypes.DANGER:
                return <Icons.ExclamationCircle />;
            case UI.ModalTypes.SUCCESS:
                return <Icons.CheckCircle />;
            default:
                return null;
        }
    }, [type])

    return (
        <div className='lex-modal-wrapper'>
            <div className='lex-modal'>
                <div className={`lex-modal-title ${type}`}>
                    {icon}{title}
                </div>
                <div className='lex-modal-body'>
                    {body}
                </div>
                <div className='lex-modal-footer'>
                    {
                        buttons.map((button, idx) =>
                            <div key={idx} className={`lex-button ${button.type}`} onClick={button.onClick}>
                                {button.text}
                            </div>
                        )
                    }
                </div>
            </div>
        </div>
    )
}

export default Modal;
