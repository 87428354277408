import { JSONContent } from "@tiptap/react";
import { BookmarkType } from "../editor-overrides/Bookmark";

// const getOffset = (el: HTMLElement) => {
//     const rect = el.getBoundingClientRect();
//     return {
//         left: rect.left + window.pageXOffset,
//         top: rect.top + window.pageYOffset,
//         width: rect.width || el.offsetWidth,
//         height: rect.height || el.offsetHeight
//     };
// }

// const connectSimplified = (el1: HTMLElement, el2: HTMLElement, connector: HTMLElement) => {
//     const rect1 = el1.getBoundingClientRect();
//     const rect2 = el2.getBoundingClientRect();

//     const distance = rect1.bottom - rect1.height / 2 - rect2.top + rect2.height / 2;

//     connector.style.height = `${Math.floor(distance)}px`;

// }

// const connect = (el1: HTMLElement, el2: HTMLElement, thickness: number) => {
//     const off1 = getOffset(el1);
//     const off2 = getOffset(el2);

//     const padding = 10;
//     const heightPaddingOffset = 4;

//     // const x1 = off1.left + off1.width;
//     // const y1 = off1.top + off1.height;
//     const x1 = off1.left - padding;
//     const y1 = off1.height / 2 - heightPaddingOffset;

//     // const x2 = off2.left + off2.width;
//     const x2 = off2.left - padding;
//     const y2 = off2.top - off1.top + off2.height / 2 - heightPaddingOffset;

//     const length = Math.sqrt(((x2 - x1) * (x2 - x1)) + ((y2 - y1) * (y2 - y1)));

//     const cx = ((x1 + x2) / 2) - (length / 2);
//     const cy = ((y1 + y2) / 2) - (thickness / 2);

//     const angle = Math.atan2((y1 - y2), (x1 - x2)) * (180 / Math.PI);


//     const lineEl = el1.getElementsByClassName('bookmark-line');


//     if (lineEl.length > 0) {
//         const relevantElement = lineEl.item(0) as HTMLElement;

//         // relevantElement.style.setProperty('--bookmark-line-height', `${padding}px`);
//         // relevantElement.style.setProperty('--bookmark-line-thickness', `${thickness}px`);
//         // relevantElement.style.setProperty('--bookmark-line-length', `${length}px`);
//         // relevantElement.style.setProperty('--bookmark-line-left', `${cx}px`);
//         // relevantElement.style.setProperty('--bookmark-line-top', `${cy}px`);
//         // relevantElement.style.setProperty('--bookmark-line-rotate-angle', `rotate(${angle}deg)`);

//         relevantElement.style.height = `${padding}px`;
//         relevantElement.style.border = `${thickness}px dashed rgb(188, 153, 207)`;
//         relevantElement.style.width = `${length}px`;
//         relevantElement.style.left = `calc(${cx}px - 0.7rem)`;
//         relevantElement.style.top = `${cy}px`;
//         relevantElement.style.transform = `rotate(${angle}deg)`;
//         relevantElement.style.borderBottom = 'none';
//     }
// }

export type BookmarkItem = {
    name: string,
    uid: string,
    hasPair: boolean,
    content?: JSONContent[]; // TODO - not used here but should be used in viewer
}

export const linkBookmarks = (bookmarkPairs: BookmarkItem[], thickness: number) => {
    // TODO - if a pair becomes false but was true (i.e. its end is deleted), the style needs to be removed
    try {
        const elements = document.getElementsByClassName('lex-bookmark');
        console.log({ bookmarkPairs, elements });

        for (const pair of bookmarkPairs.filter(p => p.hasPair)) {
            let startElement: HTMLElement | null = null;
            let endElement: HTMLElement | null = null;
            for (let i = 0; i < elements.length; i++) {
                const bkm = elements.item(i);
                if (!bkm) {
                    continue;
                }
                const bkmId = bkm.attributes.getNamedItem('uid');
                const bkmType = bkm.attributes.getNamedItem('type');
                if (bkmId && bkmId.value === pair.uid.toString()) {
                    if (bkmType && bkmType.value === BookmarkType.START) {
                        startElement = bkm as HTMLElement;
                    }
                    if (bkmType && bkmType.value === BookmarkType.END) {
                        endElement = bkm as HTMLElement;
                    }
                }
                console.log(startElement, endElement);
            }
            if (startElement && endElement) {
                // const connector = startElement.getElementsByClassName('bookmark-line').item(0) as HTMLElement;
                const connector = document.getElementById(pair.uid);
                if (connector) {
                    const rect1 = startElement.getBoundingClientRect();
                    const rect2 = endElement.getBoundingClientRect();
                    const distance = Math.floor(rect2.top + rect2.height / 2 - rect1.top - rect1.height / 2);
                    console.log('connecting', pair.name, pair.uid);
                    console.log(distance);
                    connector.style.height = `${distance}px`;
                }
            }
        }

    } catch (error) {
        console.error(error);
    }

}
