import * as Icons from 'react-bootstrap-icons';
enum Modifiers {
    META = 'META',
    CTRL = 'CTRL',
    ALT = 'ALT',
    SHIFT = 'SHIFT',
}

const modifiersMacOsIconMap = {
    [Modifiers.CTRL]: 'CTRL',
    [Modifiers.META]: <Icons.Command />,
    [Modifiers.ALT]: <Icons.Option />,
    [Modifiers.SHIFT]: <Icons.Shift />,
} as const;

const modifiersWindowsIconMap = {
    [Modifiers.CTRL]: 'CTRL',
    [Modifiers.META]: 'CTRL',
    [Modifiers.ALT]: 'ALT',
    [Modifiers.SHIFT]: 'SHIFT',
} as const;

const essentialShortcuts = [
    {
        modifiers: [Modifiers.META],
        kKey: 'C',
        desc: 'Copiere'
    },
    {
        modifiers: [Modifiers.META],
        kKey: 'X',
        desc: 'Taiere'
    },
    {
        modifiers: [Modifiers.META],
        kKey: 'V',
        desc: 'Lipire'
    },
    {
        modifiers: [Modifiers.META, Modifiers.SHIFT],
        kKey: 'V',
        desc: 'Lipire fara formatare'
    },
    {
        modifiers: [Modifiers.META],
        kKey: 'Z',
        desc: 'Undo'
    },
    {
        modifiers: [Modifiers.META, Modifiers.SHIFT],
        kKey: 'Z',
        desc: 'Redo'
    },
    {
        modifiers: [Modifiers.CTRL, Modifiers.SHIFT],
        kKey: 'H',
        desc: 'Adauga link catre alt document'
    },
]

const textFormattingShortcuts = [
    {
        modifiers: [Modifiers.META],
        kKey: 'B',
        desc: <b>Comutare text bold</b>
    },
    {
        modifiers: [Modifiers.META],
        kKey: 'I',
        desc: <i>Comutare text italic</i>
    },
    {
        modifiers: [Modifiers.META],
        kKey: 'U',
        desc: <u>Comutare text subliniat</u>
    },
    {
        modifiers: [Modifiers.META, Modifiers.SHIFT],
        kKey: 'X',
        desc: <s>Comutare text taiat</s>
    }
]

const paragraphFormattingShortcuts = [
    {
        modifiers: [Modifiers.META, Modifiers.ALT],
        kKey: '0',
        desc: 'Formatare paragraf normal'
    },
    {
        modifiers: [Modifiers.META, Modifiers.ALT],
        kKey: '1',
        desc: 'Formatare Heading 1'
    },
    {
        modifiers: [Modifiers.META, Modifiers.ALT],
        kKey: '2',
        desc: 'Formatare Heading 2'
    },
    {
        modifiers: [Modifiers.META, Modifiers.ALT],
        kKey: '3',
        desc: 'Formatare Heading 3'
    },
    {
        modifiers: [Modifiers.META, Modifiers.ALT],
        kKey: '4',
        desc: 'Formatare Heading 4'
    },
    {
        modifiers: [Modifiers.META, Modifiers.ALT],
        kKey: '5',
        desc: 'Formatare Heading 5'
    },
    {
        modifiers: [Modifiers.META, Modifiers.ALT],
        kKey: '6',
        desc: 'Formatare Heading 6'
    },
    {
        modifiers: [Modifiers.META, Modifiers.SHIFT],
        kKey: '7',
        desc: 'Formatare lista numerotata'
    },
    {
        modifiers: [Modifiers.META, Modifiers.SHIFT],
        kKey: '8',
        desc: 'Formatare lista ne-numerotata'
    },
    {
        modifiers: [Modifiers.META, Modifiers.SHIFT],
        kKey: 'L',
        desc: 'Aliniere la stanga'
    },
    {
        modifiers: [Modifiers.META, Modifiers.SHIFT],
        kKey: 'E',
        desc: 'Aliniere la centru'
    },
    {
        modifiers: [Modifiers.META, Modifiers.SHIFT],
        kKey: 'R',
        desc: 'Aliniere la dreapta'
    },
    {
        modifiers: [Modifiers.META],
        kKey: ',',
        desc: 'Subscript'
    },
    {
        modifiers: [Modifiers.META, Modifiers.SHIFT],
        kKey: '.',
        desc: 'Suprascript'
    },
]

const HeaderRow = ({ title, topGap = false }: { title: string, topGap?: boolean }) => <>
    <h4 className={`${topGap ? 'mt-4' : ''}`}>{title}</h4>
    <div className='form-control d-flex flex-row align-items-center' style={{ fontSize: '1rem', border: 'none' }}>
        <div className='' style={{ width: '10em' }}>
            Windows / Linux
        </div>
        <div className='d-flex flex-row align-items-center' style={{ width: '10em', gap: '0.25em' }}>
            MacOS
        </div>
        <div className=''>
            Detalii
        </div>
    </div>
</>

const ShortcutRow = ({ modifiers, kKey, desc }: { modifiers: Modifiers[], kKey: string, desc: any }) => {
    return (
        <div className='form-control d-flex flex-row align-items-center' style={{ fontSize: '1rem' }}>
            <div className='' style={{ width: '10em' }}>
                {modifiers.map(i => modifiersWindowsIconMap[i]).join(' + ')} + {kKey}
            </div>
            <div className='d-flex flex-row align-items-center' style={{ width: '10em', gap: '0.25em' }}>
                {modifiers.map(i => modifiersMacOsIconMap[i])} {kKey}
            </div>
            <div className=''>
                {desc}
            </div>
        </div>
    )
}
const ShortcutsInfo = () => {
    return (
        <div className='d-flex flex-column' style={{ gap: '0.25rem', width: '40rem' }}>
            <HeaderRow title='Scurtaturi esentiale' />
            {
                essentialShortcuts.map(item =>
                    <ShortcutRow
                        {...item}
                    />
                )
            }
            <HeaderRow title='Formatarea textului' topGap />
            {
                textFormattingShortcuts.map(item =>
                    <ShortcutRow
                        {...item}
                    />
                )
            }
            <HeaderRow title='Formatarea paragrafului' topGap />
            {
                paragraphFormattingShortcuts.map(item =>
                    <ShortcutRow
                        {...item}
                    />
                )
            }
        </div>
    )
}

export default ShortcutsInfo;
