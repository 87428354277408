import { DB } from "@lex/lex-types";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useOutsideClick } from "../../hooks/useOutsideClick";
import '../../assets/scss/revision-chain.scss';

const todoParseDateTime = (ts: string) => ts.replace('T', ' ');

const RevisionChain = ({
    liveDocId,
    revisions,
}: {
    liveDocId: string,
    revisions: DB.RevisionData[]
}) => {
    const [showRevisionPopup, setShowRevisionPopup] = useState(false);
    const revPopupRef = useOutsideClick(() => setShowRevisionPopup(false));

    const navigate = useNavigate();

    return (
        <div className='document-header-revisions' ref={revPopupRef}>
            <div className={`document-header-revisions-header ${showRevisionPopup ? 'open' : ''}`}>
                {
                    showRevisionPopup
                        ? 'Lista reviziilor'
                        : null
                }
                <div className='lex-button md document-header-revisions-text' onClick={() => setShowRevisionPopup(prev => !prev)}>
                    {
                        showRevisionPopup
                            ? 'Inchide'
                            : 'Vezi reviziile'
                    }
                </div>
            </div>

            {
                showRevisionPopup
                    ? <div className='document-header-revisions-popup' >
                        {
                            revisions
                                .reverse()
                                .map((rev, idx) =>
                                    <div key={idx} className={`document-header-revisions-entry ${rev.id === liveDocId ? 'active' : ''}`}
                                        onClick={() => {
                                            if (liveDocId !== rev.id) {
                                                navigate(`/edit-document/live/${rev.id}`)
                                            }
                                        }}>
                                        <div className='document-header-revisions-entry-type'>
                                            {rev.rev_type}
                                        </div>
                                        <div className='document-header-revisions-entry-ts'>
                                            {todoParseDateTime(rev.rev_date)}
                                        </div>
                                    </div>)
                        }
                    </div>
                    : null
            }
        </div>
    )
}

export default RevisionChain
