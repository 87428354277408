import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { DB } from "@lex/lex-types";
import { UserContext } from "../context/UserContext";
import { BaseContext } from "../context/BaseContext";
import Menu from "../components/to-organise/Menu";
import DocumentListPanel from "../components/to-organise/DocumentListPanel";
import CreateEmptyDocumentForm from "../components/forms/CreateEmptyDocumentForm";

import '../assets/scss/import-document.scss';
import dayjs from "dayjs";

const Home = () => {

    const { logout, decodedToken } = useContext(UserContext);
    const { searchDocuments, deleteDocument, addDocument, getActiveDrafts, draftEmptyMeta } = useContext(BaseContext);
    const [results, setResults] = useState<DB.SearchResponseEntry[]>([]);
    const [activeDrafts, setActiveDrafts] = useState<string[]>([]);
    const [currentDrafts, setCurrentDrafts] = useState<DB.SearchResponseEntry[]>([]);
    const [showCreateDocumentModal, setShowCreateDocumentModal] = useState(false);
    const navigate = useNavigate();

    const acontroller = useMemo(() => new AbortController(), []);
    const signal = acontroller.signal;

    const fetchDrafts = useCallback(async (showLoading?: boolean) => {
        return new Promise<void>((resolve, reject) => {
            searchDocuments({ publishType: DB.DocumentSearchTypes.DRAFTS_ONLY }, 20, !!showLoading)
                .then(res => {
                    if (res) {
                        setCurrentDrafts(res.docs);
                    }
                    resolve()
                });
            signal.addEventListener(('abort'), () => {
                reject();
            })
        })
    }, [searchDocuments, signal]);

    const fetchActiveDrafts = useCallback(async () => {
        const res = await getActiveDrafts();
        setActiveDrafts(res || []);
    }, [getActiveDrafts])

    const handleDeleteDocument = useCallback(async (id: string) => {
        await deleteDocument(id);
        await fetchDrafts(true);
    }, [deleteDocument, fetchDrafts])

    const handleCreateEmptyDocument = useCallback(async (meta: DB.FrontendBackendMetaDraft) => {
        const docSkeleton = {
            type: "doc",
            content: [
                {
                    type: "heading",
                    attrs:
                        { textAlign: 'center', level: 3 },
                    content: [
                        {
                            type: "text",
                            text: `${meta.type_name} Nr. ${meta.issuer_nr}`,
                            marks: [
                                { type: 'bold' }
                            ]
                        }
                    ]
                }],
        }
        const docId = await addDocument(docSkeleton, { ...draftEmptyMeta, ...meta }, [], [], undefined, true);
        if (docId) {
            navigate(`/edit-document/draft/${docId}`);
        } else {
            console.error('Unknown error creating document.')
        }
        setShowCreateDocumentModal(false);
    }, [addDocument, draftEmptyMeta, navigate]);

    useEffect(() => {
        const sync = () => {
            fetchDrafts(true);
            fetchActiveDrafts();
        }
        sync();
        const interval = setInterval(() => {
            fetchActiveDrafts()
        }, 5_000);
        return () => { acontroller.abort(); clearInterval(interval); }
    }, [acontroller, fetchActiveDrafts, fetchDrafts, searchDocuments])

    return (
        <>
            <Menu
                searchType='documents'
                userName={`${decodedToken?.firstName} ${decodedToken?.lastName}`}
                onSearchDocuments={(results) => setResults(results)}
                onHome={() => setResults([])}
                onLogout={() => { logout(); navigate('/login'); }}
                onImagesClick={() => navigate('/images')}
                onFilesClick={() => navigate('/files')}
                onSettingsClick={() => navigate('/settings')}
            />
            <div className='app-content-wrapper'>
                <div className='lex-home-page'>
                    {
                        results.length
                            ? <DocumentListPanel
                                title='Rezultatele căutării'
                                data={results}
                                noResultsText='Nu s-au gasit documente cu parametrii cautati'
                                onDeleteDoc={handleDeleteDocument}
                                showUser
                            />
                            : <div className='lex-home-row'>
                                <DocumentListPanel
                                    title='Documente draft inactive'
                                    data={currentDrafts.filter(doc => !activeDrafts.includes(doc.docid))}
                                    noResultsText='Nu sunt documente nepublicate'
                                    onDeleteDoc={handleDeleteDocument}
                                    showUser
                                />
                                <DocumentListPanel
                                    title='Documente draft in lucru'
                                    data={currentDrafts.filter(doc => activeDrafts.includes(doc.docid))}
                                    noResultsText='Nu sunt documente in lucru'
                                    onDeleteDoc={handleDeleteDocument}
                                    requireConfirmationToOpen
                                    showUser
                                />
                            </div>
                    }
                </div>
                <div className='d-flex flex-row justify-content-center w-100' style={{ gap: '0.5rem' }}>
                    <div className='lex-button success xl' onClick={() => setShowCreateDocumentModal(true)}>
                        Creaza document nou
                    </div>
                </div>
            </div>
            {
                showCreateDocumentModal
                    ? <div className='upload-popup'>
                        <CreateEmptyDocumentForm
                            onCreate={handleCreateEmptyDocument}
                            onClose={() => setShowCreateDocumentModal(false)}
                        />
                    </div>
                    : null
            }
        </>
    )
}

export default Home;
