import { DB } from '@lex/lex-types';
import { useCallback, useContext, useMemo, useState } from 'react';
import * as Icons from 'react-bootstrap-icons';
import '../../assets/scss/rel-selector.scss';
import { LoadingAndErrorsContext } from '../../context/LoadingAndErrorsContext';
import { useOutsideClick } from '../../hooks/useOutsideClick';
import RelAdd from './RelAdd';
import RelItem from './RelItem';
import TooltipWrapper from './TooltipWrapper';

const RelSelector = ({
    rels,
    isOpen,
    onClick,
    onClose,
    onChange,
}: {
    rels: DB.RelResponse[],
    isOpen: boolean,
    onClick: () => void,
    onClose: () => void,
    onChange: (newRels: DB.RelResponse[]) => void,
}) => {
    const [showAddRelPopup, setShowAddRelPopup] = useState(false);
    const { addMessage } = useContext(LoadingAndErrorsContext);

    const wrapperRef = useOutsideClick(() => onClose());

    const visibleRels = useMemo(() => rels.filter(rel => rel.state !== DB.RelStates.DELETED), [rels])

    const deleteRel = (id: number) => {
        onChange(rels.map((rel, idx) => id === idx ? { ...rel, state: DB.RelStates.DELETED } : rel));
    }

    const addRel = useCallback((rel: Omit<DB.RelResponse, 'meta'>) => {
        const newRels: DB.RelResponse[] = [];
        const foundExistingRel = rels.find(r => r.relatedDocId === rel.relatedDocId);
        if (foundExistingRel) {
            if (foundExistingRel.state !== DB.RelStates.DELETED) {
                addMessage({ text: 'Legatura exista deja', type: 'error' })
                return;
            }
            newRels.push(...rels.filter(r => r.relatedDocId !== rel.relatedDocId))
        } else {
            newRels.push(...rels);
        }
        newRels.push(rel);
        setShowAddRelPopup(false);
        onChange(newRels);
        // check if rel already exists - show an error message
    }, [addMessage, onChange, rels]);

    return (
        <div className='lex-rel-selector' ref={wrapperRef}>
            <TooltipWrapper
                desc={<span className='lex-regular-weight'>Adauga relatie</span>}
                placement='bottom'
            >
                <Icons.Bezier className={`lex-rel-selector-icon ${isOpen ? 'open' : ''}`} onClick={onClick} />
            </TooltipWrapper>
            {
                isOpen
                    ? <div className='lex-rel-selector-body'>
                        <div className='lex-rel-selector-list'>
                            {
                                visibleRels.length
                                    ? visibleRels.map((rel, idx) =>
                                        <RelItem
                                            {...rel}
                                            key={idx}
                                            onDelete={() => deleteRel(idx)}
                                        />
                                    )
                                    : <>Documentul nu are legaturi</>
                            }
                        </div>
                        <div className='lex-button success lex-rel-selector-add' onClick={() => setShowAddRelPopup(true)}>
                            Adauga legatura
                        </div>
                    </div>
                    : null
            }
            {
                showAddRelPopup
                    ? <div className='lex-popup'>
                        <RelAdd
                            onAdd={(rel) => addRel(rel)}
                            onClose={() => setShowAddRelPopup(false)}
                        />
                    </div>
                    : null
            }
        </div>
    )
}

export default RelSelector;
