import { createRef, useCallback, useContext, useEffect, useState } from "react";
import * as Icons from 'react-bootstrap-icons';
import { useNavigate } from "react-router-dom";
import { Button, Form } from "react-bootstrap";
import { Document, UI } from "@lex/lex-types";
import { UserContext } from "../context/UserContext";
import { BaseContext } from "../context/BaseContext";
import Menu from "../components/to-organise/Menu";
import Modal from "../components/to-organise/Modal";
import TooltipWrapper from "../components/to-organise/TooltipWrapper";
import { backendUrl } from "../hooks/useApi";
import '../assets/scss/files-page.scss';

const FilesPage = () => {
    const { logout, decodedToken } = useContext(UserContext);
    const { getFileUuids, generateImageURL, uploadFile, deleteFile } = useContext(BaseContext);
    const [files, setFiles] = useState<(Document.FileInfo & { hidden?: boolean, originalName: string })[]>([]);
    const [loading, setLoading] = useState(false);
    const [showUploadModal, setShowUploadModal] = useState(false);
    const [showDeleteConfirmation, setShowDeleteConfirmation] = useState("");

    const fileInput = createRef<HTMLInputElement>();

    const navigate = useNavigate();

    const fetchFiles = useCallback(async () => {
        setLoading(true);
        const filePaths = await getFileUuids(UI.FileTypes.FILE);
        if (filePaths) {
            setFiles(filePaths.map(fPath => {
                const separator = '.';
                const parts = fPath.split(separator);
                const ext = `${parts.pop()}`;
                const name = parts.join(separator);
                return ({
                    originalName: fPath,
                    name,
                    ts: 0,
                    ext,
                    url: generateImageURL(fPath),
                })
            }))
        }
        setLoading(false);
    }, [generateImageURL, getFileUuids])

    const handleUploadFile = useCallback(async (file: File) => {
        setLoading(true);
        setShowUploadModal(false);
        await uploadFile(file, UI.FileTypes.FILE);
        await fetchFiles();
        setLoading(false);
    }, [fetchFiles, uploadFile])

    useEffect(() => {
        fetchFiles();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <>
            <Menu
                searchType='generic'
                searchPlaceholder='Cauta fisiere ...'
                userName={`${decodedToken?.firstName} ${decodedToken?.lastName}`}
                onSearchGeneric={text => setFiles(files.map(f => ({ ...f, hidden: !f.name.includes(text) })))}
                onHome={() => navigate('/')}
                onLogout={() => { logout(); navigate('/login'); }}
                onImagesClick={() => navigate('/images')}
                onFilesClick={() => { }}
                onSettingsClick={() => navigate('/settings')}
            />
            <div className='app-content-wrapper'>
                <div className='lex-files-page-title'>
                    Lista fisierelor
                    <TooltipWrapper
                        desc='Urca fisier nou'
                    >
                        <div className='lex-icon-button hover-green' onClick={() => setShowUploadModal(true)}>
                            <Icons.Upload />
                        </div>
                    </TooltipWrapper>
                </div>
                <div className='lex-files-list'>
                    {
                        loading
                            ? "Loading files ..."
                            : files.length
                                ? files
                                    .filter(f => !f.hidden)
                                    .map(f =>
                                        <div className='lex-file-preview'>
                                            <TooltipWrapper desc='Salveaza fisierul'>
                                                {
                                                    backendUrl
                                                        ? <a
                                                            className='lex-file-preview-name'
                                                            href={`${backendUrl}/api/files/file/${f.originalName}`} rel="no-referrer"
                                                        >
                                                            {f.originalName}
                                                        </a>
                                                        : <div className='lex-file-preview-name'>
                                                            {f.originalName}
                                                        </div>
                                                }
                                            </TooltipWrapper>
                                            <div className='lex-file-preview-actions'>
                                                <Button variant="danger" onClick={() => setShowDeleteConfirmation(f.originalName)}>
                                                    Sterge
                                                </Button>
                                            </div>
                                        </div>
                                    )
                                : <span className="lex-image-picker-info">Nu sunt fisiere disponibile.</span>
                    }
                </div>
                {
                    showUploadModal
                        ? <Modal
                            title="Urca fisier nou"
                            type={UI.ModalTypes.INFO}
                            buttons={[
                                {
                                    type: UI.ModalButtonTypes.SAVE,
                                    text: 'Salveaza',
                                    onClick: () => {
                                        if (fileInput.current?.files?.length) handleUploadFile(fileInput.current.files[0])
                                    }
                                },
                                {
                                    type: UI.ModalButtonTypes.CANCEL,
                                    text: 'Inchide',
                                    onClick: () => setShowUploadModal(false)
                                }
                            ]}
                            body={<div className='d-flex flex-row align-items-center'>
                                <span>Fisier</span>
                                <span className="lex-spacer-1" />
                                <Form.Control
                                    placeholder="Selecteaza fisierul"
                                    type='file'
                                    ref={fileInput}
                                />
                            </div>}
                        />
                        : null
                }
                {
                    showDeleteConfirmation
                        ? <Modal
                            title="Sterge fisier"
                            type={UI.ModalTypes.DANGER}
                            buttons={[
                                {
                                    type: UI.ModalButtonTypes.DONT_SAVE,
                                    text: 'Sterge',
                                    onClick: () => {
                                        deleteFile(showDeleteConfirmation, UI.FileTypes.FILE);
                                        setShowDeleteConfirmation("");
                                        fetchFiles();
                                    }
                                },
                                {
                                    type: UI.ModalButtonTypes.CANCEL,
                                    text: 'Inchide',
                                    onClick: () => setShowDeleteConfirmation("")
                                }
                            ]}
                            body={
                                <div className='d-flex flex-row align-items-center'>
                                    Aceasta actiune este permanenta. Esti sigur ca vrei sa stergi fisierul XXX?
                                </div>
                            }
                        />
                        : null
                }
            </div>
        </>
    )
}

export default FilesPage;
