import { mergeAttributes, Node, SingleCommands } from '@tiptap/react';
import { Paragraph, ParagraphOptions } from '@tiptap/extension-paragraph';

export type BookmarkNode = {
    type: 'lex-bookmark', // in fact it's always lex-bookmark
    content: {
        type: 'text',
        text: string,
        marks?: string[],
    }[],
    attrs: {
        type: BookmarkType,
        uid: string,
        class: string,
    }
}

declare module '@tiptap/core' {
    interface Commands<ReturnType> {
        'lex-bookmark': {
            /**
             * Add the paired bookmark // allow for start-end bookmarks
             */
            setType: (type: BookmarkType) => ReturnType,
        }
    }
}

export enum BookmarkType {
    START = 'start',
    END = 'end',
}

// export type BookmarkOptions = ParagraphOptions & {
//     HTMLAttributes: {
//         [key: string]: any,
//         uid: number,
//         type: BookmarkType,
//     },
// }

export const CustomBookmark = Node.create<ParagraphOptions>({
    name: 'lex-bookmark',
    group: 'block',
    content: 'inline*',
    atom: true,

    addOptions() { // this is global - for all objects of type
        return {
            ...this.parent?.(),
            // bookmarkType: BookmarkType.START,
            // uid: 1,
            // pairUid: -1,
        }
    },
    addAttributes() {
        return {
            ...this.parent?.(),
            uid: {
                default: "",
            },
            type: {
                default: BookmarkType.START,
            },
            class: {
                default: 'lex-bookmark'
            }
        }
    },
    parseHTML() {
        return [
            {
                tag: `p[data-type="${this.name}"]`,
                priority: 1000,
            },
        ]
    },
    renderHTML({ HTMLAttributes }) {
        return ['p', mergeAttributes(this.options.HTMLAttributes, HTMLAttributes, { 'data-type': this.name, class: 'lex-bookmark', contenteditable: 'false' }), 0]
    },

    addCommands() {
        return {
            ...this.parent?.(),
            setType: (type: BookmarkType) => ({ commands }: { commands: SingleCommands }) => {
                return commands.updateAttributes(this.name, { type });
            },
        }
    }
});
