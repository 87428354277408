import { BulletList } from '@tiptap/extension-bullet-list';
import { mergeAttributes } from '@tiptap/core';

declare module '@tiptap/core' {
    interface Commands<ReturnType> {
        squareBulletList: {
            toggleSquareBulletList: () => ReturnType,
        },
        emptyCircleBulletList: {
            toggleEmptyCircleBulletList: () => ReturnType,
        },
        tickBulletList: {
            toggleTickBulletList: () => ReturnType,
        }
    }
}

const CustomBulletList = BulletList.extend({
    name: 'customBulletList',

    group: 'block list',

    content() {
        return `${this.options.itemTypeName}+`
    },

    parseHTML() {
        return [
            {
                tag: `ul[data-type="${this.name}"]`,
                priority: 51,
            },
        ]
    },

    renderHTML({ HTMLAttributes }) {
        return ['ul', mergeAttributes(this.options.HTMLAttributes, HTMLAttributes, { 'data-type': this.name }), 0]
    },
})

export const SquareBulletList = CustomBulletList.extend({
    name: 'squareBulletList',

    addCommands() {
        return {
            toggleSquareBulletList: () => ({ commands }) => {
                return commands.toggleList(this.name, this.options.itemTypeName)
            },
        }
    },
})

export const EmptyCircleBulletList = CustomBulletList.extend({
    name: 'emptyCircleBulletList',

    addCommands() {
        return {
            toggleEmptyCircleBulletList: () => ({ commands }) => {
                return commands.toggleList(this.name, this.options.itemTypeName)
            },
        }
    },
})

export const TickBulletList = CustomBulletList.extend({
    name: 'tickBulletList',

    addCommands() {
        return {
            toggleTickBulletList: () => ({ commands }) => {
                return commands.toggleList(this.name, this.options.itemTypeName)
            },
        }
    },
})
