import '../../assets/scss/labels.scss';

const Label = ({
    text,
    variant,
    size,
    theme,
    className = '',
    style = {},
}: {
    text: string,
    size: string,
    variant: string,
    theme: 'light' | 'dark',
    className?: string,
    style?: Record<string, any>
}) => {

    return (
        <div
            className={`lex-label ${variant} ${size} ${theme} ${className}`}
            style={style}
        >
            {text}
        </div>
    )
}
export default Label;
