import { useState, useEffect } from 'react';
import { jwtDecode } from 'jwt-decode';

type AuthTokenOptions = {
    expires?: Date,
    path?: string,
    secure?: boolean,
    httpOnly?: boolean,
    sameSite?: 'Strict' | 'Lax' | 'None',
}

type UseAuthTokenResult = {
    jwtToken: string,
    updateToken: (token: string) => void,
    clearToken: () => void,
}

const getCookie = (name: string): string => {
    const cookies = document.cookie.split(';').map((cookie) => cookie.trim());
    const cookie = cookies.find((cookie) => cookie.startsWith(`${name}=`));

    if (cookie) {
        return cookie.split('=')[1];
    }

    return '';
};

const setCookie = (name: string, value: string, options: AuthTokenOptions = {}): void => {
    const { expires, path, secure, httpOnly, sameSite } = options;

    let cookieString = `${name}=${value};`;

    if (expires) {
        const expirationDate = expires.toUTCString();
        cookieString += ` expires=${expirationDate};`;
    }

    if (path) {
        cookieString += ` path=${path};`;
    }

    if (secure) {
        cookieString += ' secure;';
    }

    if (sameSite) {
        cookieString += ` SameSite=${sameSite};`;
    }
    if (httpOnly) {
        cookieString += ' HttpOnly';
    }

    document.cookie = cookieString;
};

const removeCookie = (name: string): void => {
    setCookie(name, '', { expires: new Date(0), path: '/', secure: true, sameSite: 'Strict' });
};

const useAuth = (): UseAuthTokenResult => {
    const [jwtToken, setJwtToken] = useState<string>('');

    useEffect(() => {
        const token = getCookie('jwtToken');
        setJwtToken(token);
    }, []);

    const updateToken = (token: string): void => {
        setJwtToken(token);

        const decoded = jwtDecode(token);
        const { exp } = decoded;
        const expirationTimeInSeconds = 60 * 60 * 24; // 24 hours
        const expirationDate = exp ? new Date(exp * 1000) : new Date(Date.now() + expirationTimeInSeconds * 1000);
        setCookie('jwtToken', token, { path: '/', secure: true, sameSite: 'Strict', expires: expirationDate });
    };

    const clearToken = (): void => {
        setJwtToken('');
        removeCookie('jwtToken');
    };

    return { jwtToken, updateToken, clearToken };
};


export default useAuth;
