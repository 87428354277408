import { useState } from 'react';
import { HexColorPicker } from 'react-colorful';
import { useOutsideClick } from '../../hooks/useOutsideClick';

const defaultColors = ['#212529', '#008000', '#dd1f20', '#3468e3']

const ColorPicker = ({
    color = '#212529',
    onChange,
    onClose,
}: {
    color?: string,
    onChange: (newColor: string) => void,
    onClose: () => void,
}) => {
    const [localColor, setLocalColor] = useState(color);

    const wrapperRef = useOutsideClick(() => onClose());

    return (
        <div className='lex-color-picker' ref={wrapperRef}>
            <HexColorPicker color={localColor} onChange={(newColor: string) => setLocalColor(newColor)} />
            <div className='lex-color-picker-palette'>
                {
                    defaultColors.map(dColor =>
                        <div
                            key={dColor}
                            className='lex-color-picker-palette-entry'
                            style={{ backgroundColor: dColor }}
                            onClick={() => setLocalColor(dColor)}
                        />
                    )
                }
            </div>
            <div className='lex-color-picker-controls'>
                <div className='lex-button success' onClick={() => onChange(localColor)}>Salveaza</div>
                <div className='lex-button' onClick={onClose}>Inchide</div>
            </div>
        </div>
    )
}

export default ColorPicker;
