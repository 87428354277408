import { mergeAttributes, SingleCommands } from '@tiptap/react'
import { Image, ImageOptions } from '@tiptap/extension-image';

declare module '@tiptap/core' {
    interface Commands<ReturnType> {
        customImage: {
            /**
             * Add an image
             */
            setImage: (options: { src: string, alt?: string, title?: string, width?: string }) => ReturnType,
            /**
             * Update an image size
             */
            updateImageWidth: (width: number) => ReturnType,
            increaseImageWidth: () => ReturnType,
            decreaseImageWidth: () => ReturnType,
        }
    }
}

export type CustomImageOptions = ImageOptions & {
    minWidth: number,
    maxWidth: number,
    defaultWidth: number,
}

export const CustomImage = Image.extend<CustomImageOptions, any>({
    name: 'customImage',
    addAttributes() {
        return {
            ...this.parent?.(),
            width: {
                default: null,
            },
        }
    },
    addOptions() { // this is global - for all objects of type
        return {
            ...this.parent?.(),
            defaultWidth: 500,
            minWidth: 50,
            maxWidth: 500,
        }
    },
    getWidth() {
        return this.options.width;
    },
    renderHTML({ HTMLAttributes }) {
        return ['img', mergeAttributes({ width: this.options.defaultWidth }, HTMLAttributes),]
    },
    addCommands() {
        return {
            ...this.parent?.(),
            updateImageWidth: (width: number) => ({ commands }: { commands: SingleCommands }) => {
                return commands.updateAttributes(this.name, { width })
            },
            increaseImageWidth: () => ({ commands }: { commands: SingleCommands }) => {
                const currentAttributes = this.editor.getAttributes('customImage');
                const currentWidth = parseInt(currentAttributes.width || this.options.defaultWidth, 10);
                const targetWidth = Math.min(currentWidth + 10, this.options.maxWidth);
                return commands.updateImageWidth(targetWidth);
            },
            decreaseImageWidth: () => ({ commands }: { commands: SingleCommands }) => {
                const currentAttributes = this.editor.getAttributes('customImage');
                const currentWidth = parseInt(currentAttributes.width || this.options.defaultWidth, 10);
                const targetWidth = Math.max(currentWidth - 10, this.options.minWidth);
                return commands.updateImageWidth(targetWidth);
            },
        }
    },
    draggable: false,
    addKeyboardShortcuts() {
        return {
            'Mod-[': () => this.editor.commands.decreaseImageWidth(),
            'Mod-]': () => this.editor.commands.increaseImageWidth(),
        }
    },
})

// All nodes can be configured to be draggable (by default they aren’t) with this setting:

// Node.create({
//   draggable: true,
// })

// Note: see https://tiptap.dev/docs/editor/api/schema for more info
