import { Mark, mergeAttributes } from "@tiptap/react";

export const BookmarkLine = Mark.create({
    name: "bookmark-line",
    addAttributes() {
        return {
            ...this.parent?.(),
            id: {
                default: '',
            }
        }
    },

    renderHTML({ HTMLAttributes }) {
        return ["span", mergeAttributes({ class: "bookmark-line" }, HTMLAttributes), 0];
    },
    parseHTML() {
        return [{ tag: "span.bookmark-line" }];
    },
    // toDOM: ({ HTMLAttributes }: Record<string, any>) => {
    //     return ["span", mergeAttributes({ class: "bookmark-line" }, HTMLAttributes), 0];
    // },
});
