import { useCallback, useContext, useState } from "react";
import { Document } from "@lex/lex-types"
import * as Icons from 'react-bootstrap-icons';
import { BaseContext } from "../../context/BaseContext";
import '../../assets/scss/image-preview.scss';

const animationDurationSeconds = 5;

const ImagePreview = ({
    name,
    ts,
    ext,
    url,
    onClick,
}: Document.FileInfo & { onClick: (image: Document.FileInfo) => void }) => {
    const { copiedImage } = useContext(BaseContext);
    const [copied, setCopied] = useState(false);

    const handleClick = useCallback(() => {
        setCopied(true);
        onClick({ name, ts, ext, url })
        setTimeout(() => {
            setCopied(false);
        }, animationDurationSeconds * 1000)
    }, [ext, name, onClick, ts, url]);

    return (
        <div className={`lex-image-preview ${copiedImage?.url === url ? 'active' : ''}`}>
            <div className='lex-image-preview-image-wrapper' onClick={handleClick}>
                <img className='lex-image-preview-image' src={url} alt={name} />
                {
                    copied && copiedImage?.url === url
                        ? <span className='lex-image-preview-copy-confirmation' style={{ animationDuration: `${animationDurationSeconds}s` }}>
                            <Icons.Check /> Imaginea a fost copiata
                        </span>
                        : null
                }
                {/* {
                    copiedImage?.uuid === uuid
                        ? <span className='lex-image-preview-copy-url'>
                            <Icons.CheckSquareFill />
                        </span>
                        : null
                } */}
            </div>
            <span className='lex-image-preview-uuid' onClick={handleClick}>
                {name}
            </span>
        </div>
    )
}

export default ImagePreview;
