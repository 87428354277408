import { UI } from "@lex/lex-types";
import '../../assets/scss/logo.scss';

const Logo = ({ size = UI.Sizes.MEDIUM, variant = UI.Variants.LIGHT, showName = false, hideLogo = false, showVersion = false }: { size?: UI.Sizes, variant?: UI.Variants, showName?: boolean, hideLogo?: boolean, showVersion?: boolean }) => {
    return (
        <div className={`lex-editor-logo ${size} ${variant}`}>
            {
                !hideLogo
                    ? <div className='lex-editor-logo-visuals'>
                    </div>
                    : null
            }
            {
                showName
                    ? <div className='lex-editor-logo-name'>
                        Lex Editor
                    </div>
                    : null
            }
            {
                showVersion
                    ? <div className='lex-editor-logo-version'>
                        {(window as any)._env_.REACT_APP_VERSION}
                    </div>
                    : null
            }
        </div>
    )
}
export default Logo;
