import { useContext } from "react";
import { Navigate, useLocation } from "react-router-dom";
import { UserContext } from "../../context/UserContext";

type WithAuthProps = {
    redirectPath?: string
}

function withAuth<T>(
    Component: React.ComponentType<T>
) {
    const WithAuth = (props: T & WithAuthProps) => {
        const { decodedToken } = useContext(UserContext);
        const location = useLocation();

        if (!decodedToken) {
            return <Navigate
                to={{
                    pathname: props.redirectPath || '/login',
                    search: location.pathname === '/' ? undefined : 'r=' + encodeURIComponent(location.pathname)
                }}
                replace
            />
        }
        return <Component {...props} />
    };
    return WithAuth;
}

export default withAuth;
