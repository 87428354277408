import {
    createRoutesFromElements,
    createBrowserRouter,
    RouterProvider,
    Route,
} from "react-router-dom";
import Home from "./pages/Home";
import EditDocument from "./pages/EditDocument";
import { BaseContextWrapper } from "./context/BaseContext";
import { LoadingAndErrorsContextWrapper } from "./context/LoadingAndErrorsContext";
import { UserContextWrapper } from "./context/UserContext";
import Login from "./pages/Login";
import withAuth from "./components/hoc/withAuth";
import "./assets/scss/master.scss";
import FallbackPage from "./pages/FallbackPage";
import ImagesPage from "./pages/ImagesPage";
import FilesPage from "./pages/FilesPage";
import SettingsPage from "./pages/SettingsPage";

const ProtectedHome = withAuth(Home);
const ProtectedEditDocument = withAuth(EditDocument);
const ProtectedImagesPage = withAuth(ImagesPage);
const ProtectedFilesPage = withAuth(FilesPage);
const ProtectedSettingsPage = withAuth(SettingsPage);

const routes = createRoutesFromElements(
    <Route
        path="/"
        id="root"
        errorElement={<FallbackPage showHomeButton showHomeButtonAsLinkTo="/" />}
    // element={<></>} // Encapsulates all routes
    >
        <Route index={true} element={<ProtectedHome />} />
        <Route path="login" element={<Login />} />
        <Route path="images" element={<ProtectedImagesPage />} />
        <Route path="files" element={<ProtectedFilesPage />} />
        <Route path="settings" element={<ProtectedSettingsPage />} />
        <Route path="edit-document/:type/:id" element={<ProtectedEditDocument />} />
    </Route>
);

const router = createBrowserRouter(routes);

function App() {
    return (
        <div className="lex-editor-app">
            <LoadingAndErrorsContextWrapper>
                <UserContextWrapper>
                    <BaseContextWrapper>
                        <RouterProvider router={router} fallbackElement={<FallbackPage />} />
                    </BaseContextWrapper>
                </UserContextWrapper>
            </LoadingAndErrorsContextWrapper>
        </div>
    );
}

export default App;
