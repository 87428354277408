import '../../assets/scss/loader.scss';

export const Spinner = () => <div className='lex-loader-spinner' />;

const Loader = () => {

    return (
        <div className='lex-loader'>
            <Spinner />
        </div>
    )
}

export default Loader;
