import { forwardRef, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Form } from 'react-bootstrap';
import { useOutsideClick } from '../../hooks/useOutsideClick';
import * as Icons from 'react-bootstrap-icons';
import '../../assets/scss/dropdowns.scss';

export type DropdownProps = {
    isMulti: false,
    label?: string,
    value?: string,
    placeholder?: string,
    direction?: 'up' | 'down',
    tabIndex?: number,
    options: string[],
    wrap?: boolean,
    renderer?: (props: any) => JSX.Element,
    itemRenderer?: (props: any) => JSX.Element,
    onChange: (item: string) => void,
} | {
    isMulti: true,
    label?: string,
    value: string[],
    placeholder?: string,
    direction?: 'up' | 'down',
    tabIndex?: number,
    options: string[],
    wrap?: boolean,
    renderer?: (props: any) => JSX.Element,
    itemRenderer?: (props: any) => JSX.Element,
    onChange: (item: string[]) => void,
}

export const DropdownWithSearch = forwardRef((props: DropdownProps, ref: any) => {
    const { isMulti, value, placeholder, options, wrap, tabIndex, renderer, itemRenderer, onChange, direction = 'down' } = props;

    const [textSearch, setTextSearch] = useState('');
    const [isOpen, setIsOpen] = useState(false);
    const refs = useRef<HTMLDivElement[]>([]);

    const searchRef = useRef<HTMLInputElement>(null);
    const labelRef = useRef<HTMLDivElement>(null);

    const wrapperRef = useOutsideClick(() => { setIsOpen(false); setTextSearch("") });

    const displayValue = useMemo(() => {
        const newValue = isMulti
            ? value.length ? value.join(', ') : placeholder
            : value || placeholder;

        return renderer ? renderer(newValue) : newValue;
    }, [isMulti, placeholder, renderer, value])

    const handleOptionClick = useCallback((option: string) => {
        if (isMulti) {
            if (value.includes(option)) {
                onChange(value.filter(v => v !== option))
            } else {
                onChange([...value, option])
            }
        } else {
            onChange(option)
            setIsOpen(false)
        }
    }, [isMulti, onChange, value]);

    const filteredOptions = useMemo(() => {
        return options
            .filter(option => textSearch ? option.toLowerCase().includes(textSearch.toLowerCase()) : true)
    }, [options, textSearch])

    const handleKeyDown = useCallback((event: React.KeyboardEvent<HTMLDivElement>, index: number) => {
        switch (event.key) {
            case 'Escape':
                setIsOpen(false);
                break;
            case 'Enter':
            case ' ':
                if (index >= 0 && index < filteredOptions.length) {
                    event.preventDefault();
                    handleOptionClick(filteredOptions[index]);
                }
                break;
            case 'ArrowDown':
                event.preventDefault();
                const nextIndex = (index + 1) % refs.current.length;
                refs.current[nextIndex]?.focus();
                break;
            case 'ArrowUp':
                event.preventDefault();
                const prevIndex = (index - 1 + refs.current.length) % refs.current.length;
                refs.current[prevIndex]?.focus();
                break;
        }
    }, [filteredOptions, handleOptionClick])

    useEffect(() => {
        refs.current = refs.current.slice(0, filteredOptions.length);
        if (filteredOptions.length === 1) {
            refs.current[0].focus();
        }
    }, [filteredOptions]);


    useEffect(() => {
        if (isOpen) {
            searchRef.current?.focus();
        } else {
            (ref || labelRef).current?.focus();
        }
    }, [isOpen, ref])

    // not used because it removes the auto-focus from the search-box

    // useEffect(() => {
    //     if (isOpen) {
    //         searchRef.current?.focus();
    //         const optionMatchingSearch = filteredOptions.find(o => o.toLowerCase() === textSearch.toLowerCase().trim());
    //         if (optionMatchingSearch) {
    //             const idx = filteredOptions.indexOf(optionMatchingSearch);
    //             refs.current[idx].focus();
    //         }
    //     }
    // }, [filteredOptions, isOpen, textSearch])

    return (
        <div className={`lex-dropdown-select ${wrap ? 'wrap' : ''} ${direction}`} ref={wrapperRef}>
            {
                isOpen
                    ? <>
                        <Form.Control
                            ref={searchRef}
                            onKeyDown={e => {
                                if (e.key === 'ArrowDown') refs.current[0]?.focus()
                                if (e.key === 'Escape') setIsOpen(false)
                            }}
                            type='text'
                            placeholder='Cauta ...'
                            className='form-control'
                            value={textSearch}
                            onChange={(e) => setTextSearch(e.target.value)}
                        />
                        <div className={`lex-dropdown-list ${direction}`}>
                            <div
                                className='lex-dropdown-list-items'
                            >
                                {
                                    filteredOptions
                                        .map((option, idx) =>
                                            <div
                                                key={idx}
                                                tabIndex={1000 + idx}
                                                ref={el => (refs.current[idx] = el as HTMLDivElement)}
                                                className={`lex-dropdown-item ${(isMulti ? value.includes(option) : value === option) ? 'active' : ''}`}
                                                onKeyDown={e => handleKeyDown(e, idx)}
                                                onClick={() => handleOptionClick(option)}
                                            >
                                                {
                                                    itemRenderer
                                                        ? itemRenderer(option)
                                                        : option
                                                }
                                            </div>
                                        )
                                }
                            </div>
                        </div>
                    </>
                    : <div
                        className='lex-pointer lex-dropdown-trigger form-control'
                        ref={ref || labelRef}
                        tabIndex={tabIndex}
                        onKeyDown={e => {
                            if (e.key === 'Enter' || e.key === ' ') {
                                setIsOpen(true);
                            }
                        }}
                        onClick={() => {
                            setIsOpen(prev => isMulti ? !prev : true);
                        }}
                    >
                        {displayValue} <Icons.ChevronDown />
                    </div>
            }
        </div >
    )
})
