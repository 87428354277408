import { useState } from "react";
import { useOutsideClick } from "../../hooks/useOutsideClick";
import '../../assets/scss/add-symbol.scss';

const symbols: string[] = ["\u25FB", "\u25FC", "\u2713", "\u2605", "\u25E6", "\u25CF", "\u2662", "\u2666", "\u25C6", "\u25C7", "\u00AB", "\u00BB"];

const AddSymbol = ({
    onChange,
    onClose,
}: {
    onChange: (value: string) => void,
    onClose: () => void,
}) => {

    const wrapperRef = useOutsideClick(() => onClose());
    const [selectedSymbol, setSelectedSymbol] = useState('');

    return (
        <div className='lex-add-symbol-inner nopadding' ref={wrapperRef}>
            <div className='lex-add-symbol-inner-text'>
                Adauga simbol
            </div>
            <div className='lex-add-symbol-item-list'>
                {
                    symbols.map(s =>
                        <div
                            className={`lex-add-symbol-item ${selectedSymbol === s ? 'active' : ''}`}
                            onClick={() => setSelectedSymbol(s)}
                        >
                            {s}
                        </div>
                    )
                }
            </div>
            <div
                className={`lex-button success ${!selectedSymbol ? 'disabled' : 'pointer'}`}
                onClick={() => {
                    if (selectedSymbol) {
                        onChange(selectedSymbol);
                    }
                }}
            >
                Adauga
            </div>
        </div>
    )
}

export default AddSymbol;
