import * as Icons from 'react-bootstrap-icons';
import { Link } from 'react-router-dom';
import '../assets/scss/fallback.scss';

const FallbackPage = ({
    message = <>Ai gasit un link invalid</>,
    showHomeButtonAsLinkTo = '',
    showHomeButton = false,
    onHomeClick,
}: {
    message?: JSX.Element,
    showHomeButton?: boolean,
    showHomeButtonAsLinkTo?: string,
    onHomeClick?: () => void,
}) => {
    return (
        <div className='lex-fallback-page'>
            <div className='lex-fallback-message'>
                <Icons.ExclamationTriangle className='lex-fallback-icon' /> {message}
            </div>
            {
                showHomeButton
                    ? showHomeButtonAsLinkTo
                        ? <Link to={showHomeButtonAsLinkTo} className='lex-button neutral xl'>Inapoi la pagina principala</Link>
                        : <div className='lex-fallback-other'>
                            <div className='lex-button neutral' onClick={onHomeClick}>Inapoi la pagina principala</div>
                        </div>
                    : null
            }
        </div>
    )
}

export default FallbackPage;
