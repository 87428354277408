import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { UI } from '@lex/lex-types';
import '../../assets/scss/tooltipwrapper.scss';

const TooltipWrapper = ({
    children,
    id,
    desc,
    dangerouslyInnerHTMLDesc,
    placement,
    trigger,
    showDelayMs = 250,
    hideDelayMs = 100,
    theme = UI.Themes.LIGHT,
}: {
    children: any,
    id?: string,
    desc?: any,
    dangerouslyInnerHTMLDesc?: string,
    placement?: 'top' | 'right' | 'bottom' | 'left',
    trigger?: 'hover' | 'click' | 'focus' | ('hover' | 'click' | 'focus')[],
    showDelayMs?: number,
    hideDelayMs?: number,
    theme?: UI.Themes,
}) => {

    if (!desc && !dangerouslyInnerHTMLDesc) return <>{children}</>

    return (
        <OverlayTrigger
            trigger={trigger || ['hover', 'focus']}
            placement={placement || 'bottom'}
            delay={{ show: showDelayMs, hide: hideDelayMs }}
            overlay={
                <Tooltip id={id || 'button-tooltip'} className={`evs-tooltip${theme ? ` evs-tooltip-${theme}` : ''}`}>
                    {
                        dangerouslyInnerHTMLDesc
                            ? <pre className='text-start' dangerouslySetInnerHTML={{ __html: dangerouslyInnerHTMLDesc }} ></pre>
                            : desc
                    }
                </Tooltip>
            }
        >
            {children}
        </OverlayTrigger>
    )
}

export default TooltipWrapper
