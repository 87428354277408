/* eslint-disable react-hooks/exhaustive-deps */
import { useCallback, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { DB, Auth } from "@lex/lex-types"
import { UserContext } from "../../context/UserContext";
import SearchResult from "./SearchResult"

const DocumentListPanel = ({
    title,
    data,
    noResultsText,
    showUser = false,
    requireConfirmationToOpen = false,
    onDeleteDoc,
}: {
    title: string,
    data: DB.SearchResponseEntry[],
    noResultsText: string,
    showUser?: boolean,
    requireConfirmationToOpen?: boolean,
    onDeleteDoc: (id: string) => void,
}) => {
    const { decodedToken } = useContext(UserContext);
    const navigate = useNavigate();

    const canOpen = useCallback((entry: DB.SearchResponseEntry) => {
        if (!entry.docid) return false;
        if (!decodedToken) return false; // should never happen - however, a guard is needed
        return true;
    }, [decodedToken]);

    return (
        <div className='lex-home-panel'>
            <div className='lex-home-panel-heading'>{title}</div>
            <div className='lex-home-panel-body'>
                {
                    data.length
                        ? <div className='lex-search-results'>
                            {data.map((result, idx) =>
                                <SearchResult
                                    key={idx}
                                    {...result}
                                    showUser={showUser}
                                    canOpen={canOpen(result)}
                                    canDelete={canOpen(result) && result.isDraft}
                                    requireConfirmationToOpen={requireConfirmationToOpen}
                                    onClick={() => navigate(`/edit-document/${result.isDraft ? 'draft' : 'live'}/${result.docid}`)}
                                    onDelete={() => onDeleteDoc(result.docid)}
                                />
                            )
                            }
                        </div>
                        : <div className='lex-search-results message'>{noResultsText}</div>
                }

            </div>
        </div>
    )
}

export default DocumentListPanel;
