
import { useEffect, useMemo, useState } from 'react';
import * as Icons from 'react-bootstrap-icons';
import '../../assets/scss/banner.scss'

const Banner = ({ type, text, details, onDismiss, cannotClear = false, alignCenter = false, fullWidth = false, autoDismiss = false, style, className }: { type: string, text: any, details?: any, onDismiss?: () => void, cannotClear?: boolean, alignCenter?: boolean, fullWidth?: boolean, autoDismiss?: boolean, style?: any, className?: string }) => {
    const [showDetails, setShowDetails] = useState(false);

    const icon = useMemo(() => {
        switch (type) {
            case 'info':
                return <Icons.InfoCircle />
            case 'success':
                return <Icons.Check2Circle />
            case 'warning':
                return <Icons.ExclamationTriangle />
            case 'error':
                return <Icons.ExclamationCircle />
            default:
        }
    }, [type])

    const toggleShowDetails = () => {
        if (details) {
            setShowDetails(prev => !prev)
        }
    }

    useEffect(() => {
        const timeout = setTimeout(() => {
            onDismiss?.()
        }, 5000);
        return clearTimeout(timeout);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (

        <div className={`lex-banner ${type} ${className || ''}`} style={style}>
            <div className='lex-banner-header'>
                <div
                    className='lex-banner-header-text'
                    onClick={toggleShowDetails}
                    style={{
                        cursor: details
                            ? 'pointer'
                            : undefined,
                        textAlign: alignCenter
                            ? 'center'
                            : undefined,
                        width: alignCenter
                            ? '100%'
                            : undefined
                    }}
                >
                    {icon}{text}
                </div>
                {
                    !cannotClear
                        ? <Icons.X onClick={() => onDismiss?.()} className='lex-banner-close-icon' />
                        : null
                }
            </div>
            {
                details && showDetails
                    ? <div className='lex-banner-details'>{details}</div>
                    : null
            }
        </div>
    )
}

export default Banner;
