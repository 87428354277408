import { Extensions } from '@tiptap/react'
import { Table } from '@tiptap/extension-table';
import { TableRow } from '@tiptap/extension-table-row';
import { TableHeader } from '@tiptap/extension-table-header';
import { } from '@tiptap/extension-task-list';
import { TableCell } from '@tiptap/extension-table-cell';
import { Superscript } from '@tiptap/extension-superscript';
import { Subscript } from '@tiptap/extension-subscript';
import { Underline } from '@tiptap/extension-underline';
import TextStyle from '@tiptap/extension-text-style';
import { Color } from '@tiptap/extension-color';
import TextAlign from '@tiptap/extension-text-align';
import Link from '@tiptap/extension-link';
import { OrderedList, OrderedListOptions, inputRegex } from '@tiptap/extension-ordered-list';
import { BulletList, BulletListOptions } from '@tiptap/extension-bullet-list';
import { CustomShortcuts } from './Shortcuts';
import { CustomImage } from './Image';
import { EmptyCircleBulletList, SquareBulletList, TickBulletList } from './Lists';
import { CustomBookmark } from './Bookmark';
import { BookmarkLine } from './Marks';

export const editorExtensions: Extensions = [
    CustomShortcuts.configure(),
    Table.configure({
        allowTableNodeSelection: true,
    }),
    TableRow.configure(),
    TableHeader.configure({
        HTMLAttributes: {
            style: 'border: 1px solid black'
            // TODO - use classNames instead
        }
    }),
    TableCell.configure({
        HTMLAttributes: {
            style: 'border: 1px solid black'
            // TODO - use classNames instead
        }
    }),
    OrderedList.configure({
        keepAttributes: true,
        keepMarks: true,
    }),
    BulletList.configure({
        keepAttributes: true,
        keepMarks: true,
    }),
    SquareBulletList.configure({
        keepAttributes: true,
        keepMarks: true,
    }),
    EmptyCircleBulletList.configure({
        keepAttributes: true,
        keepMarks: true,
    }),
    TickBulletList.configure({
        keepAttributes: true,
        keepMarks: true,
    }),
    CustomImage.configure({
        inline: true,
        defaultWidth: 300, // default width - if not set
    }),
    Superscript,
    Subscript,
    Underline,
    TextStyle,
    Color.configure({
        types: ['textStyle']
    }),
    TextAlign.configure({
        types: ['heading', 'paragraph'],
        alignments: ['left', 'right', 'center'],
        defaultAlignment: 'left',
    }),
    Link.configure({
        openOnClick: false,
        autolink: true,
    }),
    CustomBookmark,
    BookmarkLine,
]
