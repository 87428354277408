import { createRef, useCallback, useContext, useEffect, useState } from "react";
import * as Icons from 'react-bootstrap-icons';
import { Form } from "react-bootstrap";
import { Document, UI } from "@lex/lex-types";
import { useOutsideClick } from "../../hooks/useOutsideClick";
import { BaseContext } from "../../context/BaseContext";
import TooltipWrapper from "./TooltipWrapper";
import ImagePreview from "./ImagePreview";
import Modal from "./Modal";
import '../../assets/scss/image-picker.scss';

const ImagePicker = ({
    canMaximize = false,
    constrained = false,
    onAdd,
    onClose,
}: {
    canMaximize?: boolean,
    constrained?: boolean, // if true, it cancels the absolute position
    onAdd?: (url: string) => void,
    onClose: () => void
}) => {

    const { updateCopiedImage, generateImageURL, getFileUuids, copiedImage, uploadFile } = useContext(BaseContext);
    const [fullScreen, setFullScreen] = useState(false);
    const [images, setImages] = useState<Document.FileInfo[]>([]);
    const [loading, setLoading] = useState(false);
    const [showUploadModal, setShowUploadModal] = useState(false);

    const fileInput = createRef<HTMLInputElement>();

    const wrapperRef = useOutsideClick(() => onClose());

    const fetchImages = useCallback(async () => {
        setLoading(true);
        const filePaths = await getFileUuids(UI.FileTypes.IMAGE);
        if (filePaths) {
            setImages(filePaths.map(fPath => {
                const separator = '.';
                const parts = fPath.split(separator);
                const ext = `${parts.pop()}`;
                const ts = parseInt(parts.pop() || '0', 10);
                const name = parts.join(separator);
                return ({
                    name,
                    ts,
                    ext,
                    url: generateImageURL(fPath),
                })
            }))
        }
        setLoading(false);
    }, [generateImageURL, getFileUuids]);

    const handleUploadImage = useCallback(async (file: File) => {
        setLoading(true);
        setShowUploadModal(false);
        await uploadFile(file, UI.FileTypes.IMAGE);
        await fetchImages();
        setLoading(false);
    }, [fetchImages, uploadFile])

    useEffect(() => {
        fetchImages();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    return (
        <div className={`lex-image-picker ${fullScreen ? 'full-screen' : ''} ${constrained ? 'constrained' : ''}`} ref={wrapperRef}>
            <div className='lex-image-picker-subwrap'>
                <div className='lex-image-picker-header'>
                    Imagini
                    {
                        copiedImage
                            ? <TooltipWrapper
                                desc='Adauga imaginea selectata'
                            >
                                <div className={`lex-icon-button hover-green ${canMaximize ? 'me-auto' : ''}`} onClick={() => { onAdd?.(copiedImage.url); updateCopiedImage(); }}>
                                    <Icons.PlusSquare />
                                </div>
                            </TooltipWrapper>
                            : null
                    }
                    <TooltipWrapper
                        desc='Imagine noua'
                    >
                        <div className={`lex-icon-button hover-green ms-auto`} onClick={() => setShowUploadModal(true)}>
                            <Icons.Upload />
                        </div>
                    </TooltipWrapper>
                    {
                        canMaximize
                            ? <>
                                {
                                    fullScreen
                                        ? <TooltipWrapper
                                            desc='Iesi din Full screen'
                                        >
                                            <div className='lex-icon-button hover-blue' onClick={() => setFullScreen(false)}>
                                                <Icons.FullscreenExit />
                                            </div>
                                        </TooltipWrapper>
                                        : <TooltipWrapper
                                            desc='Full screen'
                                        >
                                            <div className='lex-icon-button hover-blue' onClick={() => setFullScreen(true)}>
                                                <Icons.Fullscreen />
                                            </div>
                                        </TooltipWrapper>
                                }
                                <TooltipWrapper
                                    desc='Inchide'
                                >
                                    <div className='lex-icon-button hover-red' onClick={() => onClose()}>
                                        <Icons.XSquare />
                                    </div>
                                </TooltipWrapper>
                            </>
                            : null
                    }
                </div>
                <div className='lex-image-picker-items'>
                    {
                        loading
                            ? "Loading images ..."
                            : images.length
                                ? images.map(image =>
                                    <ImagePreview
                                        key={`${image.name}-${image.ts}`}
                                        {...image}
                                        onClick={updateCopiedImage}
                                    />
                                )
                                : <span className="lex-image-picker-info">Nu sunt imagini disponibile.</span>
                    }
                </div>
            </div>
            {
                showUploadModal
                    ? <Modal
                        title="Urca imagine noua"
                        type={UI.ModalTypes.INFO}
                        buttons={[
                            {
                                type: UI.ModalButtonTypes.SAVE,
                                text: 'Salveaza',
                                onClick: () => {
                                    if (fileInput.current?.files?.length) handleUploadImage(fileInput.current.files[0])
                                }
                            },
                            {
                                type: UI.ModalButtonTypes.CANCEL,
                                text: 'Inchide',
                                onClick: () => setShowUploadModal(false)
                            }
                        ]}
                        body={<div className='d-flex flex-row'>
                            <span>Imagine</span>
                            <span className="lex-spacer-1" />
                            <Form.Control
                                placeholder="Selecteaza fisierul"
                                type='file'
                                ref={fileInput}
                            />
                        </div>}
                    />
                    : null
            }

        </div>
    )
}

export default ImagePicker;
